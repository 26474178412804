import React, { ReactElement, useEffect } from "react";

import { IoClose } from "react-icons/io5";

import {
  ModalContentStyledWrapper,
  ModalHeaderStyledWrapper,
  ModalStyledWrapper,
  OverlayStyled,
} from "./Modal.styled";

interface ModalProps {
  modalTitle?: string;
  isOpen: boolean;
  onClose: () => void;
  children?: ReactElement | ReactElement[];
}

export const Modal = (props: ModalProps): ReactElement => {
  const { modalTitle = "", isOpen = false, onClose, children } = props;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <div>
      <ModalStyledWrapper $isOpen={isOpen}>
        <ModalHeaderStyledWrapper>
          <span>{modalTitle}</span>

          <IoClose size={24} onClick={onClose} />
        </ModalHeaderStyledWrapper>

        <ModalContentStyledWrapper>{children}</ModalContentStyledWrapper>
      </ModalStyledWrapper>

      <OverlayStyled $isOpen={isOpen} />
    </div>
  );
};
