import React, { ReactElement, useEffect, useRef, useState } from "react";

import { IconContainer, NavigationSearchItemContainer, SearchContainer, SearchInput, SearchResultItem, SearchResultsContainer, SearchStyledWrapper } from "./Search.styled";
import { CiSearch } from "react-icons/ci";
import { gardenItemApi } from "../../../api/requests/garden-item.api";
import { GardenItemInterface } from "../../../types/interfaces/GardenItem.interface";

interface SearchProps { }

export const Search = (props: SearchProps): ReactElement => {
  const { } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<GardenItemInterface[]>([]);
  const [showResults, setShowResults] = useState(false);  // Добавляем состояние для отображения результатов

  const [plants, setPlants] = useState<any[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);  // Создаем реф для контейнера

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await gardenItemApi.getGardenItemSearch();
        setPlants(data);
      } catch (error) {
        console.error("Error fetching garden data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Добавляем обработчик клика для скрытия результатов
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setShowResults(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearch = (event: any) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value.length > 0) {
      const results = plants.filter(item =>
        item.data.name.toLowerCase().includes(value.toLowerCase())
      );
      setSearchResults(results);
      setShowResults(true);  // Показываем результаты
    } else {
      setSearchResults([]);
      setShowResults(false);  // Скрываем результаты, если поле пустое
    }

  };

  return (
    <NavigationSearchItemContainer ref={wrapperRef}> {/* Привязываем реф к контейнеру */}
      <SearchContainer>
        <IconContainer>
          <CiSearch size={24} />
        </IconContainer>
        <SearchInput
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
          onFocus={() => setShowResults(true)}  // Показываем результаты при фокусе на инпуте
        />
        {showResults && searchResults.length > 0 && (
          <SearchResultsContainer>
            {searchResults.map(result => (
              <SearchResultItem key={result.id} href={`/gardens/${result.garden.id}?itemGardenId=${result.id}`}>
                {/* <img src={result.image[0].imageUrl} /> */}
                <img src={result.image.imageUrl} />
                <span>{String(result.data.name)}</span>
              </SearchResultItem>
            ))}
          </SearchResultsContainer>
        )}
      </SearchContainer>
    </NavigationSearchItemContainer>
  );
};
