import React, { ReactElement } from "react";

import { ButtonStyledWrapper } from "./Button.styled";

interface ButtonProps {
  label: string;
  onClick: () => void;
}

export const Button = (props: ButtonProps): ReactElement => {
  const {
    label,
    onClick,
  } = props;

  return (
    <ButtonStyledWrapper onClick={onClick}>
      {label}
    </ButtonStyledWrapper>
  );
};
