import React from "react";
import { Route, Routes } from "react-router-dom";
import { Navigation } from "./components/shared-components/Navigation/Navigation";
import { AppContentStyledWrapper, AppStyledWrapper, ContentMenuContainer } from "./App.styled";
import { ContentMenuPage } from "./components/composit-components/ContentMenu/ContentMenu";
import { Footer } from "./components/shared-components/Footer/Footer";
import { HomePage } from "./pages/Home/Home";
import { NotFoundPage } from "./pages/NotFoundPage/NotFoundPage";
import { GardenItemPage } from "./pages/GardenItem/GardenItem";
import { useAuth } from "./hooks/use-auth-hook/useAuth";
import { BasketPage } from "./pages/Bascet/Bascet";
import { AboutPage } from "./pages/About/About";
import { ContactsPage } from "./pages/Contacts/Contacts";
import { GalleryPage } from "./pages/Gallery/Gallery";
import { CancellationPage } from "./pages/Cancellation/Cancellation";
import { PrivacyPage } from "./pages/Privacy/Privacy";
import { TermsConditionsPage } from "./pages/TermsConditions/TermsConditions";
import { SignupPage } from "./pages/SignupPage/SignupPage";
import { SigninPage } from "./pages/SigninPage/SigninPage";
import { PersonalPage } from "./pages/PersonalPage/PersonalPage";

function App() {
  useAuth();

  return (
    <AppStyledWrapper>
      <Navigation />
      <AppContentStyledWrapper>
        <ContentMenuContainer style={{ padding: "10px 0" }}>
          <ContentMenuPage />
        </ContentMenuContainer>
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="/bascet" element={<BasketPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/cancellation" element={<CancellationPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/terms-and-conditions" element={<TermsConditionsPage />} />
          <Route path="/gardens/:id" element={<GardenItemPage />} />

          <Route path="/signin" element={<SigninPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/personal-account" element={<PersonalPage />} />

          <Route path="*" element={<NotFoundPage />} />

        </Routes>
      </AppContentStyledWrapper>

      <Footer />
    </AppStyledWrapper>
  );
}

export default App;
