import React, { ReactElement, useState } from "react";

import { PersonalAccountStyledWrapper } from "./PersonalAccount.styled";
import { PersonalAccountView } from "./PersonalAccountView/PersonalAccountView";
import { PersonalAccountEdit } from "../PersonalAccountEdit/PersonalAccountEdit";

export const PersonalAccount = (): ReactElement => {
  const [isEdit, setIsEdit] = useState(false);

  const handleOpenEdit = () => {
    setIsEdit(!isEdit);
  };

  return (
    <PersonalAccountStyledWrapper>
      {!isEdit && <PersonalAccountView onOpenEdit={handleOpenEdit} />}
      {isEdit && <PersonalAccountEdit onCloseEdit={handleOpenEdit} />}
    </PersonalAccountStyledWrapper>
  );
};
