import styled from "styled-components";

export const ButtonStyledWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00C000;
    padding: 10px 20px;
    min-width: 100px;
    color: #fff;
    border-radius: 4px;
    min-width: 150px;
    cursor: pointer;
`;
