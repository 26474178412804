import React, { ReactElement } from "react";
import { MdPerson } from "react-icons/md";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { MdPhoneAndroid } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { BsFillPostcardFill } from "react-icons/bs";
import { TbNumber } from "react-icons/tb";

import {
  PersonalAccountViewStyledWrapper,
  UserFieldStyledWrapper,
} from "./PersonalAccountView.styled";
import { observer } from "mobx-react-lite";
import { useUserStore } from "../../../../hooks/use-user-store-hook/useUserStore";
import { ActionBarStyledWrapper } from "../PersonalAccount.styled";
import { Button } from "@mui/material";

const NO_DATA = "No data";

export const PersonalAccountView = observer(({ onOpenEdit }: any): ReactElement => {
  const { user } = useUserStore();
  const { name, email, phone, address, postCode, numberVAT } = user || {};

  return (
    <PersonalAccountViewStyledWrapper>
      <h3 style={{ marginBottom: 10 }}>Personal info</h3>
      <div style={{display:"flex", flexDirection:"row", justifyContent:"left", gap:"20px"}}>
        <div>
          <UserFieldStyledWrapper>
            <p className="label">
              <MdPerson />
              Name
            </p>

            <p className="value">{name || NO_DATA}</p>
          </UserFieldStyledWrapper>

          <UserFieldStyledWrapper>
            <p className="label">
              <MdOutlineAlternateEmail />
              Email
            </p>

            <p className="value">{email || NO_DATA}</p>
          </UserFieldStyledWrapper>

          <UserFieldStyledWrapper>
            <p className="label">
              <MdPhoneAndroid />
              Phone
            </p>

            <p className="value">{phone || NO_DATA}</p>
          </UserFieldStyledWrapper>
        </div>
        <div>
          <UserFieldStyledWrapper>
            <p className="label">
              <IoLocationSharp />
              Name of organization
            </p>

            <p className="value">{address || NO_DATA}</p>
          </UserFieldStyledWrapper>

          <UserFieldStyledWrapper>
            <p className="label">
              <BsFillPostcardFill />
              Postal Code
            </p>

            <p className="value">{postCode || NO_DATA}</p>
          </UserFieldStyledWrapper>

          <UserFieldStyledWrapper>
            <p className="label">
              <TbNumber />
              Number VAT
            </p>

            <p className="value">{numberVAT || NO_DATA}</p>
          </UserFieldStyledWrapper>
        </div>
      </div>
      <ActionBarStyledWrapper>
        <Button variant="outlined" onClick={onOpenEdit}>
          Edit
        </Button>
      </ActionBarStyledWrapper>
    </PersonalAccountViewStyledWrapper>
  );
});
