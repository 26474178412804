import { BascetContainer, BascetLink, BascetDetailWeight, BascetNumberProducts, BascetTitle, ContentMenuMobileContainer, LogoWrapper, NavigationBascetItemContainer, NavigationBascetMobileItemContainer, NavigationContainer, NavigationInfoItemContainer, NavigationLogoItemContainer, NavigationLogoMobileItemContainer, NavigationMobileStyledWrapper, NavigationSearchItemContainer, NavigationStyledWrapper, NavigationWorkingItemContainer } from "./Navigation.styled";
import { LiaPhoneSolid } from "react-icons/lia";
import { TfiEmail } from "react-icons/tfi";
import { Logo } from "../Logo/Logo";
import { Search } from "../Search/Search"
import { ContentMenuMobile } from "../ContentMenuMobile/ContentMenuMobile";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { useBascetStore } from "../../../hooks/use-bascet-store-hook/useBacetStore";
import { observer } from "mobx-react-lite";

export const Navigation = observer(() => {
  const { bascet, summaryWeight } = useBascetStore();

  return (
    <div>
      <NavigationStyledWrapper>
        <NavigationContainer>
          <NavigationInfoItemContainer>
            <div style={{ marginBottom: "8px", display: "flex", justifyItems: "center" }}>
              <div>
                <LiaPhoneSolid size={19} style={{ marginRight: "6px", }} />
              </div>
              <a href="tel:+447832695407">+44 7832 695407</a>
            </div>
            <div style={{ display: "flex", justifyItems: "center" }}>
              <div>
                <TfiEmail size={19} style={{ marginRight: "6px" }} />
              </div>
              <p style={{ whiteSpace: "nowrap" }}>officeaiop@gmail.com</p>
            </div>
          </NavigationInfoItemContainer>
          <NavigationWorkingItemContainer>
            <div style={{ alignItems: "center" }}>
              <div style={{ marginBottom: "8px" }}>Working hours</div>
              <div>09:00-17:00</div>
            </div>
          </NavigationWorkingItemContainer>
          <NavigationLogoItemContainer >
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
          </NavigationLogoItemContainer>
          <Search />
          <NavigationBascetItemContainer>
            <BascetLink to="/bascet">
              <BascetContainer>
                <BascetTitle>Basket</BascetTitle>
                <BascetDetailWeight>{summaryWeight.toFixed(1)} kg</BascetDetailWeight>
                <BascetNumberProducts>{Math.floor(summaryWeight / 400)} pallets</BascetNumberProducts>
              </BascetContainer>
            </BascetLink>
          </NavigationBascetItemContainer>
        </NavigationContainer>
      </NavigationStyledWrapper >

      <NavigationMobileStyledWrapper>
        <ContentMenuMobileContainer>
          <ContentMenuMobile />
        </ContentMenuMobileContainer>
        <NavigationLogoMobileItemContainer >
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </NavigationLogoMobileItemContainer>
        <NavigationBascetMobileItemContainer>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <BascetLink to="/bascet">
              <BascetContainer>
                <BascetTitle><HiOutlineShoppingCart /></BascetTitle>
                <BascetDetailWeight>{summaryWeight.toFixed(1)} kg</BascetDetailWeight>
                <BascetNumberProducts>{Math.floor(summaryWeight / 400)} palets</BascetNumberProducts>
              </BascetContainer>
            </BascetLink>
          </div>
        </NavigationBascetMobileItemContainer>
      </NavigationMobileStyledWrapper>
    </div >
  );
});
