import React, { ReactElement, useEffect } from "react";

import { AboutContainer, AboutDetailsContainer, AboutStyledWrapper, AboutTitle, AboutUsImg, AboutUsInfo, AboutUsInfoContainer } from "./About.styled";

interface AboutPageProps { }

export const AboutPage = (props: AboutPageProps): ReactElement => {
  const { } = props;

  useEffect(() => {
    document.title = `About : A&O Plants`;
  }, []);

  return (
    <AboutStyledWrapper>
      <AboutContainer>
        <AboutDetailsContainer>
          <AboutTitle>About</AboutTitle>
          <AboutUsInfoContainer>
            <AboutUsInfo>
              A&O PLANTS is a wholesale supplier of top-quality plants, catering to businesses of all sizes. With a dedication to excellence, we provide a wide range of plants, meticulously cultivated to meet the highest standards. Our flexible approach to customer service ensures that we tailor our offerings to meet the unique needs of our clients. At A&O PLANTS, we prioritize quality, reliability, and customer satisfaction, making us the ideal partner for businesses seeking exceptional plants for their customers.              <br />
            </AboutUsInfo>
            <AboutUsInfo>
              <AboutUsImg src={"/about-us-image.jpg"} />
            </AboutUsInfo>
          </AboutUsInfoContainer>
        </AboutDetailsContainer>
      </AboutContainer>
    </AboutStyledWrapper>
  );
};
