import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import styled from "styled-components";

export const InputNumberStyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100px;

  @media screen and (max-width: 1440px) {
    width: 70px;
  }


  @media (max-width: 768px) {
    width: 46%;
    padding: 0;
    justify-content: center;
    flex-direction: column;

  }
`;

export const ArrowContainer = styled.div`
    display: flex;
    flex-direction: column;
    user-select: none;
`;

export const QuantityMobileContainer = styled.div`
    display: none;

    @media (max-width: 768px) {
      display: block;
      font-size: 18px;
      padding-bottom: 8px;


  }
`;

export const ValueDisplay = styled.div`
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 16px;


  }
`;

export const InputNumberContainer = styled.div`
display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #CCF2CC;
  position: relative;
  padding: 8px 14px 7px 14px;
  width: 100px;
    @media (max-width: 768px) {
      display: none;
  }
`;

export const InputNumberMobileContainer = styled.div`
    display: none;

@media (max-width: 768px) {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  background-color: #CCF2CC;
  padding: 7px 6px 7px 10px;
  width: 100%;


}
`;


export const ArrowDownIcon = styled(IoIosArrowDown)<{ isDisabled: boolean }>`
  size: 20px;
  color: black;
  cursor: pointer;

  @media (max-width: 768px) {
    size: 18px; 
  }
`;

export const ArrowUpIcon = styled(IoIosArrowUp)<{ isDisabled: boolean }>`
  size: 20px;
  color: black;
  cursor: pointer;

  @media (max-width: 768px) {
    size: 18px; 
  }
`;