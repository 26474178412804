import styled from "styled-components";
import { contentWidth, mobile } from "../../styles/variables.styled";

export const GardenItemStyledWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

    @media screen and (max-width: ${mobile}) {
      padding: 0;

    }
  `;

  export const GardenItemContainer = styled.div`
    width: 100%;
    max-width: ${contentWidth};
    display: flex;
    flex-direction: column;
    justify-content: center;
  
  `;

export const ItemTitleContainer = styled.div`
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/title-image-background.jpg");
    background-position: center;
    background-size: cover;
    border-radius: 4px;

    
  @media screen and (max-width: ${mobile}) {
    height: 85px;

  }
  
  `;

  export const ItemTitleName = styled.p`
    color: white;
    font-size: 24px;
  
  `;

export const SearchMobileContainer = styled.div`
  display: none;

  @media screen and (max-width: ${mobile}) {
    display: flex;
    margin: 5px 0;
  }
`;
