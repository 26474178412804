import styled from "styled-components";

export const PersonalAccountViewStyledWrapper = styled.div`
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const UserFieldStyledWrapper = styled.p`
  font-size: 16px;
  margin-bottom: 10px;

  .label {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;

    svg {
      margin-top: -2px;
      margin-right: 2px;
    }
  }

  .value {
    font-size: 18px;
    padding-top: 2px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
  }
`;
