import React, { ReactElement, useState } from "react";

import { AcardioneStyledWrapper, AcardioneItem, Header, Topic, Icon, ContentContainer, ContentInner } from "./Accardione.styled";
import { FaAngleDown, FaAngleRight } from "react-icons/fa6";
interface AccordionItem {
  title: string;
  content: any;
}

interface AccardioneProps {
  items: AccordionItem[];
}

export const Acardione = ({ items }: AccardioneProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <AcardioneStyledWrapper>
      {items.map((item, index) => (
        <AcardioneItem key={index} isopen={activeIndex === index}>
          <Header onClick={() => toggleAccordion(index)}>
            <Topic>{item.title}</Topic>
            <Icon isopen={activeIndex === index}>
              {activeIndex === index ? <FaAngleRight color="white"/> : <FaAngleDown color="white"/>}
            </Icon>
          </Header>
          <ContentContainer isopen={activeIndex === index}>
            <ContentInner>{item.content}</ContentInner>
          </ContentContainer>
        </AcardioneItem>
      ))}
    </AcardioneStyledWrapper>
  );
};
