import styled from "styled-components";

export const PersonalAccountStyledWrapper = styled.div``;

export const ActionBarStyledWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    gap: 8px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;
