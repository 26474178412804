import React, { ReactElement, useEffect, useState } from "react";

import { BackButton, ContentMenuMobileStyledWrapper, EmptyDropDawnMenu, FirstMenuItem, MainSectionMenuDropDawn, MobileMenuButton, MobileMenuCloseButton, MobileMenuContainer, MobileMenuDropdown, MobileMenuItem, SubMenuItem } from "./ContentMenuMobile.styled";
import { FaBars } from "react-icons/fa6";
import { RiMenu2Fill } from "react-icons/ri";
import { IoCloseOutline } from "react-icons/io5";
import { gardenApi } from "../../../api/requests/garden.api";
import { useUserStore } from "../../../hooks/use-user-store-hook/useUserStore";
import { Button } from "@mui/material";
import { Loader } from "../Loader/Loader";
interface ContentMenuMobileProps { }




export const ContentMenuMobile = (props: ContentMenuMobileProps): ReactElement => {
  const { } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [animationType, setAnimationType] = useState<"slideIn" | "slideOut" | null>(null);
  const [isPlantMenuOpen, setIsPlantMenuOpen] = useState(false);
  const [isSubmenuAnimating, setIsSubmenuAnimating] = useState(false);
  const [submenuAnimationType, setSubmenuAnimationType] = useState<"slideIn" | "slideOut" | null>(null);
  const { user } = useUserStore();

  const [plants, setPlants] = useState<any[]>([]);
  const [loading, setLoading] = useState(true); // Добавляем состояние загрузки

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await gardenApi.getGarden();
        setPlants(data);
      } catch (error) {
        console.error("Error fetching garden data:", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (animationType) {
      setIsAnimating(true);
      const timer = setTimeout(() => {
        if (animationType === "slideOut") {
          setIsMenuOpen(false);
        }
        setIsAnimating(false);
        setAnimationType(null);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [animationType]);

  useEffect(() => {
    if (submenuAnimationType) {
      setIsSubmenuAnimating(true);
      const timer = setTimeout(() => {
        setIsSubmenuAnimating(false);
        setSubmenuAnimationType(null);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [submenuAnimationType]);

  const toggleMenu = () => {
    if (isMenuOpen) {
      setAnimationType("slideOut");
    } else {
      setIsMenuOpen(true);
      setAnimationType("slideIn");
    }
    setIsPlantMenuOpen(false);
  };

  const openPlantMenu = () => {
    setIsPlantMenuOpen(true);
    setSubmenuAnimationType("slideIn");
  };

  const closePlantMenu = () => {
    setSubmenuAnimationType("slideOut");
    setTimeout(() => {
      setIsPlantMenuOpen(false);
    }, 300);
  };

  const menuItems = [
    { id: 1, name: 'A&O Plants Ltd', href: '/' },
    {
      id: 2, name: 'Plants',
      subItems: plants
    },
    { id: 3, name: 'About', href: '/about' },
    { id: 4, name: 'Gallery', href: '/gallery' },
    { id: 5, name: 'Contacts', href: '/contacts' },
  ];

  return (
    <MobileMenuContainer>
      <MobileMenuButton onClick={toggleMenu}>
        {isMenuOpen && !isAnimating ? <IoCloseOutline size={36} /> : <RiMenu2Fill size={36} />}
      </MobileMenuButton>
      {(isMenuOpen || isAnimating) && (
        <MobileMenuDropdown animationType={animationType}>
          <MainSectionMenuDropDawn style={{ backgroundColor: "white" }}>
            <MobileMenuCloseButton onClick={toggleMenu}>
              <IoCloseOutline size={40} />
            </MobileMenuCloseButton>
            {menuItems.map((item, index) => (
              index === 0 ? (
                <FirstMenuItem key={item.id} href={item.href}>
                  {item.name}
                </FirstMenuItem>
              ) : item.name === 'Plants' ? (
                <MobileMenuItem key={item.id} href="#" onClick={openPlantMenu}>
                  {item.name}
                </MobileMenuItem>
              ) : (
                <MobileMenuItem key={item.id} href={item.href}>
                  {item.name}
                </MobileMenuItem>
              )
            ))}
            {loading ? (
              <Loader />
            ) : user?.id ? (<p>
              <Button variant="outlined" color="primary" href="/personal-account">
                {user?.name}
              </Button>
            </p>
            ) : (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button style={{ margin: "2px 5px 0 0" }} variant="outlined" color="primary" href="/signin">
                  Sign In
                </Button>

                <Button style={{ margin: "2px 0 0 0" }} variant="outlined" color="primary" href="/signup">
                  Sign Up
                </Button>
              </div>
            )}
          </MainSectionMenuDropDawn>
          <EmptyDropDawnMenu onClick={toggleMenu}></EmptyDropDawnMenu>
        </MobileMenuDropdown>
      )}
      {isPlantMenuOpen && (
        <MobileMenuDropdown animationType={submenuAnimationType}>
          <MainSectionMenuDropDawn style={{ backgroundColor: "white" }}>
            <BackButton onClick={closePlantMenu}>              <IoCloseOutline size={40} />
            </BackButton>
            <SubMenuItem style={{ color: "#00C000" }}>
              Plants
            </SubMenuItem>
            {menuItems.find(item => item.name === 'Plants')?.subItems?.map(subItem => (
              <SubMenuItem key={subItem.id} href={`/gardens/${subItem.id}`}>
                {subItem.name}
              </SubMenuItem>
            ))}
          </MainSectionMenuDropDawn>
          <EmptyDropDawnMenu onClick={closePlantMenu}></EmptyDropDawnMenu>
        </MobileMenuDropdown>
      )}
    </MobileMenuContainer>
  );
};