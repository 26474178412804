import React, { ReactElement, useEffect, useState } from "react";

import { PersonalAccountEditStyledWrapper } from "./PersonalAccountEdit.styled";
import { observer } from "mobx-react-lite";
import { useUserStore } from "../../../hooks/use-user-store-hook/useUserStore";
import { Button, Input } from "@mui/material";
import { ActionBarStyledWrapper } from "../PersonalAccount/PersonalAccount.styled";
import { userApi } from "../../../api/requests/user.api";

export const PersonalAccountEdit = observer(
  ({ onCloseEdit }: any): ReactElement => {
    const { user, setUser } = useUserStore();
    const { name, email, phone, address, postCode, numberVAT } = user || {};

    const [currentUser, setCurrentUser] = useState(user);

    const handleCurrentUserChange = (key: string) => (e: any) => {
      setCurrentUser((prevUser: any) => ({
        ...prevUser,
        [key]: e.target.value,
      }));
    };

    useEffect(() => {
      setCurrentUser(user);
    }, [user]);

    const handleSave = async () => {
      const { name, email, phone, address, postCode, numberVAT } = currentUser || {};

      await userApi.updateUser({
        name,
        email,
        phone,
        address,
        postCode,
        numberVAT,
      });

      const updatedUser = await userApi.getUser();
      setUser(updatedUser);

      onCloseEdit();
    };

    return (
      <PersonalAccountEditStyledWrapper>
        <h3 style={{ marginBottom: 10 }}>Edit personal info</h3>

        <Input
          placeholder="Name"
          value={currentUser?.name}
          onChange={handleCurrentUserChange("name")}
          className="input"
        />

        <Input
          placeholder="Email"
          value={currentUser?.email}
          onChange={handleCurrentUserChange("email")}
          className="input"
        />

        <Input
          placeholder="Phone"
          value={currentUser?.phone}
          onChange={handleCurrentUserChange("phone")}
          className="input"
        />

        <Input
          placeholder="Name of organization"
          value={currentUser?.address}
          onChange={handleCurrentUserChange("address")}
          className="input"
        />

        <Input
          placeholder="Postal Code"
          value={currentUser?.postCode}
          onChange={handleCurrentUserChange("postCode")}
          className="input"
        />

        <Input
          placeholder="Number VAT"
          value={currentUser?.numberVAT}
          onChange={handleCurrentUserChange("numberVAT")}
          className="input"
        />

        <ActionBarStyledWrapper>
          <Button
            variant="contained"
            onClick={handleSave}
            style={{ color: "#fff" }}
          >
            Save
          </Button>
          <Button variant="outlined" onClick={onCloseEdit}>
            Cancel
          </Button>
        </ActionBarStyledWrapper>
      </PersonalAccountEditStyledWrapper>
    );
  }
);
