import React, { ReactElement, useState } from "react";

import { CustomCheckBoxStyledWrapper, Container, Label, CheckboxIcon, DiscountText, StyledFaCheckSquare } from "./CustomCheckBox.styled";
import { FaCheckSquare, FaSquare } from 'react-icons/fa';

interface CustomCheckBoxProps {
  checked: boolean;
  onCheckChange: (checked: boolean) => void;
}

export const CustomCheckBox = ({checked, onCheckChange}: CustomCheckBoxProps): ReactElement => {
  const handleCheckboxChange = () => {
    onCheckChange(!checked);
  };
  return (
    <Container onClick={handleCheckboxChange}>
      <CheckboxIcon>
        {checked ? <StyledFaCheckSquare  /> : <FaSquare  />}
      </CheckboxIcon>
      {<DiscountText>2%</DiscountText>}
    </Container>
  );
};
