import React, { ReactElement, useState } from "react";

import { SigninFormStyledWrapper, Title, Text } from "./SigninForm.styled";
import { Input } from "../../shared-components/Input/Input";
import { Button } from "../../shared-components/Button/Button";
import { authApi } from "../../../api/requests/auth.api";
import { PhoneConfirmationModal } from "../../shared-components/PhoneConfirmationModal/PhoneConfirmationModal";
import { localStorageUtils } from "../../../utils/browser-utils/LocalStorage";
import { observer } from "mobx-react-lite";
import { useUserStore } from "../../../hooks/use-user-store-hook/useUserStore";
import { useNavigate } from "react-router-dom";


const errorMessagesMap: Record<string, Record<string, string>> = {
  phone: {
    PHONE_IS_EMPTY: "Phone is empty",
    PHONE_IS_NOT_VALID: "Phone is not valid",
  },
};
export const SigninForm = observer((): ReactElement => {
  const { setUser } = useUserStore();
  const navigate = useNavigate();

  const [phone, setPhone] = useState("");

  const [code, setCode] = useState("");
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const handleStartRegister = async () => {
    const data = await authApi.startSignIn(phone);
    const { isStarted, errors } = data;

    if (errors && errors?.length) {
      const isUserAlreadyExists = errors.includes("USER_ALREADY_EXISTS");
      const isAgreementNotAccepted = errors.includes(
        "AGREEMENT_IS_NOT_ACCEPTED"
      );

      setErrors(errors);

      return;
    }

    if (isStarted) {
      setIsConfirmationOpen(true);
    }
  };

  const handleRegister = async () => {
    const isCodeValid = !code.split("").includes("·");

    if (!isCodeValid) {
      setErrors(["CODE_IS_INVALID"]);
      return;
    }

    const {
      expiresIn,
      refreshToken,
      refreshTokenExpiresIn,
      token,
      user,
      errors,
    } = await authApi.signIn(phone, code);

    if (errors && errors.length) {
      setErrors(errors);

      return;
    }

    localStorageUtils.setAllAuthUserInfo({
      expiresIn,
      refreshToken,
      refreshTokenExpiresIn,
      token,
    });

    setUser(user);
    navigate("/");
  };

  const handleCodeChange = (code: string) => {
    setCode(code);
  };

  return (
    <SigninFormStyledWrapper>
      <Title>Sign in</Title>

      <Input
        placeholder="Phone"
        type="text"
        onChange={(e) => setPhone(e.target.value)}
        value={phone}
        isValid={
          !Boolean(
            errors.includes("PHONE_IS_NOT_VALID") ||
              errors.includes("PHONE_IS_EMPTY")
          )
        }
        errorMessage={errors.reduce((acc: string[], error: string) => {
          if (errorMessagesMap.phone[error]) {
            acc.push(errorMessagesMap.phone[error]);
          }

          return acc;
        }, [])}
      />

      <Button label="Sign In" onClick={handleStartRegister} />

      <Text>
        Don't have an account yet?{" "}
        <a
          style={{ color: "#00C000", textDecoration: "underline" }}
          href="/signup"
        >
          Register
        </a>
      </Text>

      <PhoneConfirmationModal
        phone={phone}
        modalTitle="Sign in"
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onSubmit={handleRegister}
        onCodeChange={handleCodeChange}
        isCodeValid={
          Boolean(
            errors.includes("CODE_IS_EMPTY") ||
              errors.includes("CODE_IS_NOT_VALID") ||
              errors.includes("CODE_IS_INVALID")
          )
        }
      />
    </SigninFormStyledWrapper>
  );
});
