import styled from "styled-components";

export const PersonalAccountEditStyledWrapper = styled.div`
    display: flex;
    flex-direction: column;

    .input {
        margin-bottom: 20px;
        width: 300px;
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
    }
`;
