import React, { ReactElement, useEffect, useState } from "react";

import { PersonalContentStyledWrapper, PersonalPageStyledWrapper, PersonalTabsStyledWrapper } from "./PersonalPage.styled";
import { Tabs, Tab } from "@mui/material";
import { PersonalAccount } from "../../components/composit-components/PersonalAccount/PersonalAccount";
import { PersonalOrders } from "../../components/composit-components/PersonalOrders/PersonalOrders";

interface PersonalPageProps {}

enum AccountTabsEnum {
  Profile = "Profile",
  Orders = "Orders",
};

const accountTabsHeaders = {
  [AccountTabsEnum.Profile]: "Profile",
  [AccountTabsEnum.Orders]: "Orders",
};

const content = {
  [AccountTabsEnum.Profile]: <PersonalAccount />,
  [AccountTabsEnum.Orders]: <PersonalOrders />,
}

export const PersonalPage = (props: PersonalPageProps): ReactElement => {
  const {} = props;

  const [activeTab, setActiveTab] = useState<AccountTabsEnum>(AccountTabsEnum.Profile);

  useEffect(() => {
    document.title = `User Page : A&O Plants`;
  }, []);


  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: AccountTabsEnum) => {
    setActiveTab(newValue);
  };

  return (
    <PersonalPageStyledWrapper>
      <PersonalTabsStyledWrapper>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab value={AccountTabsEnum.Profile} label={accountTabsHeaders[AccountTabsEnum.Profile]} />
          <Tab value={AccountTabsEnum.Orders} label={accountTabsHeaders[AccountTabsEnum.Orders]} />
        </Tabs>
      </PersonalTabsStyledWrapper>

      <PersonalContentStyledWrapper>
        {content[activeTab]}
      </PersonalContentStyledWrapper>
    </PersonalPageStyledWrapper>
  );
};
