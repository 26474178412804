import styled from "styled-components";

export const PaginationStyledWrapper = styled.div``;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const PaginationButton = styled.button`
  background-color: #f0f0f0;
  color: #333;
  border: none;
  cursor: pointer;
  padding: 8px 12px;
  margin: 0 2px;
  border-radius: 4px;
  font-size: 14px;

  &:hover {
    background-color: #e0e0e0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export const PaginationEllipsis = styled.span`
  margin: 0 4px;
`;