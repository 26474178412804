import styled from "styled-components";

export const PersonalPageStyledWrapper = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    width: 100%;
`;

export const PersonalTabsStyledWrapper = styled.div`
    margin-bottom: 20px;
`;

export const PersonalContentStyledWrapper = styled.div``;
