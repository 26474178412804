import React, { ReactElement, useEffect } from "react";


import { TermsConditionsContainer, TermsConditionsStyledWrapper, TermsConditionsTitle, TermsInfo } from "./TermsConditions.styled";

interface TermsConditionsPageProps { }

export const TermsConditionsPage = (props: TermsConditionsPageProps): ReactElement => {
  const { } = props;

  useEffect(() => {
    document.title = `Terms : A&O Plants`;
  }, []);


  return (
    <TermsConditionsStyledWrapper>
      <TermsConditionsContainer>
        <TermsInfo>
          <TermsConditionsTitle>Work in progress...</TermsConditionsTitle>
        </TermsInfo>
      </TermsConditionsContainer>
    </TermsConditionsStyledWrapper>
  );
};
