import { GardenItemContainer, GardenItemStyledWrapper, ItemTitleContainer, ItemTitleName, SearchMobileContainer } from "./GardenItem.styled";
import { Table } from "../../components/composit-components/Table/Table";
import { Search } from "../../components/shared-components/Search/Search";
import { useLocation, useParams } from "react-router-dom";
import { gardenItemApi } from "../../api/requests/garden-item.api";
import { gardenApi } from "../../api/requests/garden.api";
import { useState, useEffect } from "react";
import { Loader } from "../../components/shared-components/Loader/Loader";
interface GardenItemPageProps { }

export const GardenItemPage = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [searchGardenId, setSearchGardenId] = useState<string | null>(null);

  const [itemGardenData, setItemGardenData] = useState<any>(null);
  const [gardenData, setGardenData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchId = queryParams.get('itemGardenId');
    setSearchGardenId(searchId);

    const fetchData = async () => {
      if (id) {
        try {
          const itemGardenData = await gardenItemApi.getGardenItemByGardenId(id);
          setItemGardenData(itemGardenData);
          const gardenData = await gardenApi.getGardenById(id);
          setGardenData(gardenData);
          document.title = `Product - ${gardenData.name} : A&O Plants`;
        } catch (err: any) {
          setError(`Failed to fetch garden data: ${err.message}`);
        }
      }
    };

    fetchData();
  }, [id, location.search]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!gardenData) {
    return <Loader style={{ width: "100%", height: '250px' }}/>

  }

  return (
    <GardenItemStyledWrapper>
      <GardenItemContainer>
        <ItemTitleContainer>
          <ItemTitleName>{gardenData.name}</ItemTitleName>
        </ItemTitleContainer>
        <SearchMobileContainer>
          <Search />
        </SearchMobileContainer>
        <div style={{ width: "100%" }}>
          <Table gardenId={id} searchGardenItemId={searchGardenId} />
        </div>
      </GardenItemContainer>
    </GardenItemStyledWrapper>
  );
};