import styled from "styled-components";
import { contentWidth, mobile } from "../../styles/variables.styled";

export const TermsConditionsStyledWrapper = styled.div`
width: 100%;
display: flex;
justify-content: center;
`;

export const TermsConditionsContainer = styled.div`
width: ${contentWidth};
padding: 0 0 10px 0;

@media screen and (max-width: ${mobile}) {
    padding: 10px 0;
  }
`;

export const TermsConditionsTitle = styled.div`
text-align:center;
padding: 150px 0;
font-size: 30px;
`;

export const TermsInfo = styled.div`
background-color: #fff;

`;
