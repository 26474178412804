import styled from "styled-components";
import { contentWidth, mobile } from "../../styles/variables.styled";

export const GalleryStyledWrapper = styled.div`
width:100%;
display: flex;
justify-content: center;
`;

export const GalleryContainer = styled.div`
width: ${contentWidth};
padding: 0 0 10px 0;

@media screen and (max-width: ${mobile}) {
    padding: 10px 0;
}
`;

export const GalleryContent = styled.div`
background-color: #fff;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const GalleryTitle = styled.div`
font-weight: bold;
font-size: 24px;
padding: 40px 0 30px 0;
`;

export const GalleryImageContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
padding-bottom: 10px ;
gap: 20px;
`;

export const GalleryBlockContainer = styled.div`
height: 560px;
display: flex;
flex-direction: column;
justify-content: space-between;
margin: 0 10px;
gap:20px;
@media screen and (max-width: ${mobile}) {
    height: 280px;
}

&.third-block {
  @media screen and (max-width: ${mobile}) {
    display: none;
  }
}
`;

export const ImageBigContainer = styled.div`
width: 280px;
height: 310px;
cursor: pointer;
@media screen and (max-width: ${mobile}) {
    width: 134px;
    height: 160px;
}
`;

export const ImageSmallContiner = styled.div`
width: 280px;
height: 230px;
cursor: pointer;
@media screen and (max-width: ${mobile}) {
    width: 134px;
    height: 100px;
}

`;

export const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
object-position: center calc(100% + 30px); 
  @media screen and (max-width: ${mobile}) {
    object-position: center 50%;
    }
`;

export const GalleryImageBlock = styled.div`
display: flex;
flex-direction: row;

`;

export const ImageContainerModal = styled.div`
  height: 600px;
  width: auto;

  @media screen and (max-width: ${mobile}) {
    width: 280px;
    height: 100%;
  }
`;


export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #e0e0e0;
  }
`;