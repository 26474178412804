import styled from "styled-components";
import { mobile } from "../../../styles/variables.styled";

export const SearchStyledWrapper = styled.div``;

export const NavigationSearchItemContainer = styled.div`
  width: 22%;
  @media screen and (max-width: ${mobile}) {
    width: 100%;
    background-color: #CCF2CC;
    border-radius: 4px;

  }

`;

export const IconContainer = styled.div`
  @media screen and (max-width: ${mobile}) {
    margin-left: 7px;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const SearchWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const SearchInput = styled.input`
  border: none;
  border-radius:  5px 5px 0 0;
  background-color: #CCF2CC;
  outline: none;
  padding: 5px 5px 5px 10px;
  margin-left: 10px;
  height: 42px;
  width: calc(100% - 34px); 

  @media screen and (max-width: ${mobile}) {
    width: 100%;
  }
`;

export const SearchResultsContainer = styled.div`
  position: absolute;
  top: 42px; 
  right: 0;
    background-color: #CCF2CC;
border-top: solid #00C000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: calc(100% - 34px); 
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;

  @media screen and (max-width: ${mobile}) {
    width:100%; 
  }
`;

export const SearchResultItem = styled.a`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #B4E1B4;
  }

  img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
`;