import styled from "styled-components";
import { contentWidth, mobile } from "../../../styles/variables.styled";

export const FooterStyledWrapper = styled.footer`
  display: flex;
  justify-items: center;
  justify-content: center;
  background-color: #fff;
  width: 100%;
  padding: 50px 10%;

  @media screen and (max-width: 1440px) {
    transform: scale(0.8);
    transform-origin: 0 0;
    width: 125%; 
    padding: 50px 10%;
    height: 280px;

  }

  @media screen and (max-width: ${mobile}) {
    display: none;
  }

`;

export const FooterContainer = styled.footer`
  width: ${contentWidth};
  display:flex;
  flex-direction: row;
  justify-content: space-between;
`;



export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h2, h3 {
    margin-bottom: 10px;
    font-weight: normal;

    @media screen and (max-width: ${mobile}) {
    margin-bottom: 5px;
    font-size: 16px;
    }
    
   }

  address {
    margin-bottom: 10px;
    margin-top: 10px;
    font-style: normal;
  }

  div {
    display: flex;
    gap: 10px;
  }
`;

export const FooterLink = styled.a`
  color: #000;
  text-decoration: none;
  margin-top: 20px;

  @media screen and (max-width: ${mobile}) {
    margin-top: 10px;
    font-size: 14px;
  }

`;

export const FooterMap = styled.div`
  @media screen and (max-width: ${mobile}) {
    iframe {
    border: 0;
    width: 100%;
    height: 100%;
  }
}
`;

export const FooterMobileStyledWrapper = styled.footer`
  display: none;

  @media screen and (max-width: ${mobile}) {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 0 20px 0;
  }

`;

export const AddressLogoContainer = styled.div`

  @media screen and (max-width: ${mobile}) {
      display: flex;
      flex-direction: row;
      width: 100%;
      overflow: hidden;
      padding: 0 15px;
      justify-content: space-between;
  }

`;

export const MenuFooterItemContainer = styled.div`

  @media screen and (max-width: ${mobile}) {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      overflow: hidden;
      padding: 26px 15px 0 15px;
    }

`;


export const FooterLogoMobileColumn = styled.div`

  @media screen and (max-width: ${mobile}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 30%;

  h2, h3 {
    margin-bottom: 10px;
    font-weight: normal;
   }

  address {
    margin-bottom: 14px;
    margin-top: 14px;
    font-size: 14px;
    font-style: normal;
  }

  div {
    display: flex;
    gap: 10px;
  }
  }

  @media screen and (max-width: 390px) {
     flex-direction: column;
    align-items: flex-start;
    width: 30%;
    
  h2, h3 {
    margin-bottom: 10px;
    font-weight: normal;
   }

  address {
    margin-bottom: 14px;
    margin-top: 14px;
    font-size: 12px;
    font-style: normal;
  }

  div {
    display: flex;
    gap: 10px;
  }
  }

`;



export const IconsMobileContainer = styled.div`

  @media screen and (max-width: ${mobile}) {
    display: flex;
    justify-content: space-between;
    width: 90%;
  }

`;

export const FooterMapMobileColumn = styled.div`

  @media screen and (max-width: ${mobile}) {
    display: flex;

    align-items: stretch;
      width: 62%;
  }

`;
