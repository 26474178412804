import styled from "styled-components";

export const NotFoundPageStyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 39vh;
  overflow: hidden;
    font-weight: bold;
    font-size: 30px;

`;
