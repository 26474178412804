import { httpClient } from "../httpClient/HttpClient";

export class GardenItemApi {
  private GARDENITEM_ENDPOINT = "item-garden";

  getGardenItem = async (): Promise<any> => {
    const data = await httpClient.get(`${this.GARDENITEM_ENDPOINT}/`);

    return data;
  };

  getGardenItemPagination = async (page: number, limit: number, gardenId: string): Promise<any> => {
    const data = await httpClient.get(`${this.GARDENITEM_ENDPOINT}/page?page=${page}&limit=${limit}&gardenId=${gardenId}`);
    return data;
  };

  getFilteredGardenItems = async (page: number, limit: number, gardenId: string, filter: string): Promise<any> => {
    const data = await httpClient.get(`${this.GARDENITEM_ENDPOINT}/filter?page=${page}&limit=${limit}&gardenId=${gardenId}&filter=${filter}`);
    return data;
  };

  getGardenItemfirstInSearch = async (gardenId: string, itemGardenId: string): Promise<any> => {
    const data = await httpClient.get(`${this.GARDENITEM_ENDPOINT}/search-first?page=1&limit=6&gardenId=${gardenId}&itemGardenId=${itemGardenId}`);
    return data;
  };


  getGardenItemSearch = async (): Promise<any> => {
    const data = await httpClient.get(`${this.GARDENITEM_ENDPOINT}/search`);
    return data;
  };

  getGardenItemByGardenId = async (gardenId: string): Promise<any> => {
    const data = await httpClient.get(`${this.GARDENITEM_ENDPOINT}/garden/${gardenId}`);

    return data;
  };

  updateGardenItem = async (idGarden: string, toUpdateGardenItem: any) => {
    const data = await httpClient.put(`${this.GARDENITEM_ENDPOINT}/update/${idGarden}`, toUpdateGardenItem)
    return data;
  }
}

export const gardenItemApi = new GardenItemApi();
