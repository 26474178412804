import styled from "styled-components";
import { contentWidth, mobile } from "../../styles/variables.styled";

export const PrivacyStyledWrapper = styled.div`
width: 100%;
display: flex;
justify-content: center;
`;

export const PrivacyContainer = styled.div`
width: ${contentWidth};
padding: 0 0 10px 0;

@media screen and (max-width: ${mobile}) {
    padding: 10px 0;
  }
`;

export const PrivacyTitle = styled.div`
text-align:center;
padding: 20px 0 30px 0;
font-size: 30px;
`;

export const PrivacyInfo = styled.div`
background-color: #fff;
display: flex;
flex-direction: column;
gap: 14px;
padding: 40px;

`;
