import styled from "styled-components";

export const ModalStyledWrapper = styled.div<{ $isOpen: boolean }>`
    display: ${({ $isOpen }) => ($isOpen ? "flex" : "none")};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 300px;
    background-color: #fff;
    z-index: 10001;
    flex-direction: column;

    @media (max-width: 768px) {
        width: 90%;

}
`;

export const OverlayStyled = styled.div<{ $isOpen: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;

    display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
`;

export const ModalHeaderStyledWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    height: 50px;

    svg {
        cursor: pointer;
    }
`;

export const ModalContentStyledWrapper = styled.div`
    padding: 10px;
`;

export const ModalFooterStyledWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
`;
