import { IoCheckmark } from "react-icons/io5";
import styled from "styled-components";


export const CustomCheckBoxStyledWrapper = styled.div``;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  font-size: 16px;
  margin-right: 8px;
`;

export const CheckboxIcon = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  color: white;
  border: 1px solid #00C000;
`;

export const StyledFaCheckSquare = styled(IoCheckmark)`
  color: transparent; 
  stroke: #00C000;
  & > path {
    &:nth-of-type(1) {
      color: black; 
    }
    &:nth-of-type(0) {
      color: #00C000; 
    }
  }
`;

export const DiscountText = styled.span`
  font-size: 16px;
  margin-left: 8px;
  font-weight: bold;
`;
