import React, { ReactElement, useEffect } from "react";

import { SigninPageStyledWrapper } from "./SigninPage.styled";
import { SigninForm } from "../../components/composit-components/SigninForm/SigninForm";

interface SigninPageProps {}

export const SigninPage = (props: SigninPageProps): ReactElement => {
  const {} = props;

  useEffect(() => {
    document.title = `Sign In : A&O Plants`;
  }, []);


  return (
    <SigninPageStyledWrapper>
      <SigninForm />
    </SigninPageStyledWrapper>
  );
};
