import styled from "styled-components";
import { contentWidth, mobile } from "../../../styles/variables.styled"
import { Link } from "react-router-dom";

export const NavigationStyledWrapper = styled.div`
  display: flex;
  position: fixed; 
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
  top: 0; 
  left: 0;
  right: 0;
  height: 120px; 
  background-color: white;
  z-index: 9999; 

  @media screen and (max-width: 1440px) {
    transform: scale(0.8);
    transform-origin: 0 0;
    width: 125%; 
    
  }

  @media screen and (max-width: ${mobile}) {
    display: none;
  }
`;

export const NavigationContainer = styled.div`
width: ${contentWidth};
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 82%;
  margin-left: 30px;
`;

export const SearchInput = styled.input`
  border: none;
  border-radius: 5px;
  background-color: #CCF2CC;
  outline: none;
  padding: 5px;
  margin-left: 10px;
  height: 42px;
  width: 82%;
`;

export const BascetTitle = styled.div`
  font-weight: bold;
  line-height: 16.8px;
`;

export const BascetDetailWeight = styled.div`
line-height: 14.4px;
  margin:8px 0 8px 0px ;

  @media screen and (max-width: ${mobile}) {
    margin:2px 4px 2px 4px ;

  }
`;

export const BascetNumberProducts = styled.div`
line-height: 14.4px;

`;

export const BascetContainer = styled.div`
  border-radius: 5px;
  background-color: #CCF2CC;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px;

  @media screen and (max-width: ${mobile}) {
    padding: 6px 14px;

  }
  `;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  grid-column: span 1;
`;

export const NavigationInfoItemContainer = styled.div`
    width: 16%;
`;
export const NavigationWorkingItemContainer = styled.div`
  display: flex; 
  flex-direction:column; 
  align-items:center;
    width: 16%;
`;
export const NavigationLogoItemContainer = styled.div`
    width: 16%;
`;
export const NavigationSearchItemContainer = styled.div`
    width: 22%;
`;
export const NavigationBascetItemContainer = styled.div`
    width: 10%;
`;

export const NavigationMobileStyledWrapper = styled.div`
display:none;

@media screen and (max-width: ${mobile}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    position: fixed; 
    top: 0; 
    left: 0;
    right: 0;
    height: 86px; 
    background-color: white; 
    z-index: 1000; 
  }

`;

export const NavigationLogoMobileItemContainer = styled.div`
    display:none;

    @media screen and (max-width: ${mobile}) {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 35%;
    margin: 0 15px;

  }

`;

export const NavigationBascetMobileItemContainer = styled.div`
    display:none;
    @media screen and (max-width: ${mobile}) {
    display: flex;
    justify-content: space-between;

  }

`;

export const ContentMenuMobileContainer = styled.div`
    display:none;

    @media screen and (max-width: ${mobile}) {
    display: flex;
    align-items: center;
    justify-content: center;

  }

`;

export const BascetLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
