import { IoIosArrowDown } from "react-icons/io";
import styled from "styled-components";
import { mobile } from "../../../styles/variables.styled";

interface PaginationButtonProps {
  active?: boolean;
}

interface ItemDetailsProps {
  isOpen: boolean; 
}


export const TableStyledWrapper = styled.div`
  width: 100%;
  margin: 10px 0;
`;

export const TableContainer = styled.div`
  width: 100%;

  @media  (max-width: 768px) {
    display: none;

  }
`;

export const ImageContainerModal = styled.div`
  height: 600px;
  width: auto;

  @media screen and (max-width: ${mobile}) {
    width: 280px;
    height: 100%;
  }
`;



export const HeadConteiner = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 40px;
  background-color: #fff;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin: 10px 0;
  padding: 10px 0;
  background-color: #fff;
`;

export const Cell = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 8%;
padding: 8px;

@media screen and (max-width: 768px) {
  width: auto;

  }
`;

export const CellImage = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 8%;
padding: 8px;
padding-left: 30px;

@media screen and (max-width: 1440px) {
  padding-left: 20px;

     }
@media screen and (max-width: 768px) {
  width: auto;

  }
`;

export const AddItemButtonContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 18%;
padding: 8px;

@media screen and (max-width: 768px) {
  width: 100%;
  padding: 14px 0;

  }
`;


export const HeaderCellContainer = styled(Cell)`
  cursor: pointer;
  background-color: #fff;
  text-align: center;
  flex-direction: column;
  
`;

export const HeaderCell = styled(Cell)`
  cursor: pointer;
  background-color: #fff;
  text-align: center;

`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const PaginationButton = styled.button<{ active?: boolean }>`
  padding: 8px 12px;
  margin: 0 5px;
  background-color: ${({ active }) => (active ? "#4CAF50" : "transparent")};
  color: ${({ active }) => (active ? "white" : "#333")};
  border: 1px solid #ddd;
  cursor: pointer;

  &:hover {
    background-color: ${({ active }) => (active ? "#4CAF50" : "#f4f4f4")};
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

export const PaginationEllipsis = styled.span`
  margin: 0 5px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;

  svg {
    position: absolute;
    top: 70%;
    left: 20%;
    transform: translate(-50%, -50%);
    color: rgba(0, 192, 0, 0.5);
    font-size: 24px;
    pointer-events: none; 
  }

  @media screen and (max-width: 1440px) {
    height: 66px;
    width: 66px; 
     }
`;


export const ItemImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
`;

export const ButtonLink = styled.div`
  width: 100%;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #9e9e9e; 
  background-color: #fff;
  border: 1px solid #B4B6B5;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #f4f4f4;
    color:  #00C000;
  }
`;

export const RadioButtonLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;

  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + span {
    display: inline-block;
    min-width: 16px;
    min-height: 16px;
    margin: 0 3px;
    border: 2px solid black;
    border-radius: 50%;
    position: relative;

    @media screen and (max-width: 768px) {
    display: block;

  }
  }

  input[type="radio"]:checked + span::before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background: linear-gradient(to bottom right, #a8e6cf, #004d00);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  background-color: #dfffe1;
  padding: 8px 12px;
  font-size: 16px;
  width: 100px;
  text-align: center;
  border-bottom: 2px solid transparent;
  cursor: pointer;

  &:focus {
    border-bottom: 2px solid #00C000;
  }
`;

export const Option = styled.option`
  background-color: #dfffe1;
  border: none;
  padding: 8px 12px;
  font-size: 16px;
`;

export const TableMobileContainer = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;

  }
`;

export const TableHeaderMobileContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 40px;
  background-color: #fff;
`;


export const HeaderCellMobileContainer = styled(Cell)`
  cursor: pointer;
  background-color: #fff;
  text-align: center;
  flex-direction: column;
`;

export const InfoItemContainer = styled.div`
  display: flex;
  width: 56%;
  flex-direction: column;
`;


export const PurchaseItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 44%;
`;


export const RowTableMobileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin: 10px 0;
  padding: 10px 10px;
  background-color: #fff;
  border-radius: 4px;

`;

export const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  background-color: #fff;
`;

export const ItemMainInfoContainer = styled.div`
display: flex;
flex-direction: row;

`;


export const ItemDetailedInfoContainer = styled.div`

`;


export const MainInfo = styled.div`

`;

export const ItemDetails = styled.div<ItemDetailsProps>`
  max-height: ${(props) => (props.isOpen ? '500px' : '0')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
`;

export const ToggleButton = styled.div`
  display: flex;
  justify-content: left;
  color: black;
  border: none;
  padding: 8px 0;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;

`;

export const ArrowDownIcon = styled(IoIosArrowDown)`
  size: 20px;

  @media (max-width: 768px) {
    size: 18px; 
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #e0e0e0;
  }
`;