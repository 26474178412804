import styled from "styled-components";

export const PhoneConfirmationModalStyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .buttons {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
`;

export const WordingsStyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
`;