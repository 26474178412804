import { makeAutoObservable } from "mobx";
import { UserInterface } from "../types/interfaces/User.interface";

export class UserStore {
  public user: UserInterface | null = null;
  public ordersHistory: any[] = [];

  constructor() {
    makeAutoObservable(this);

    this.setUser = this.setUser.bind(this);
    this.setOrdersHistory = this.setOrdersHistory.bind(this);
  }

  setUser(user: UserInterface) {
    this.user = user;
  }

  setOrdersHistory(ordersHistory: any[]) {
    this.ordersHistory = ordersHistory;
  }
}
