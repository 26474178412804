import React, { ReactElement } from "react";

import { PhoneConfirmationModalStyledWrapper, WordingsStyledWrapper } from "./PhoneConfirmationModal.styled";
import { Modal } from "../Modal/Modal";
import { VerificationCodeInput } from "../VerificationCodeInput/VerificationCodeInput";
import { Button } from "../Button/Button";

interface PhoneConfirmationModalProps {
  modalTitle?: string;
  isOpen: boolean;
  phone: string;
  isCodeValid: boolean;
  onSubmit: () => void;
  onClose: () => void;
  onCodeChange: (code: string) => void;
}

export const PhoneConfirmationModal = (
  props: PhoneConfirmationModalProps
): ReactElement => {
  const { modalTitle, isCodeValid, phone, isOpen, onSubmit, onClose, onCodeChange } = props;

  const handleClose = () => {
      onClose();
  };

  return (
    <Modal modalTitle={modalTitle} onClose={handleClose} isOpen={isOpen}>
      <PhoneConfirmationModalStyledWrapper>
        <WordingsStyledWrapper>
          <h3>Check your text messages</h3>
          <p>{phone}</p>
        </WordingsStyledWrapper>

        <VerificationCodeInput
          onChange={onCodeChange}
          autoFocus
          value={""}
          isInvalid={isCodeValid}
        />

        <div className="buttons">
          <Button label="Confirm" onClick={onSubmit} />
        </div>
      </PhoneConfirmationModalStyledWrapper>
    </Modal>
  );
};
