import styled from "styled-components";
import { contentWidth } from "../../../styles/variables.styled";

export const ContentMenuStyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  border-radius: 4px;
 cursor: default;
 `;

export const ContentContainer = styled.div`
  width: ${contentWidth};
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;

`;

export const ContentMenuItem = styled.div`
  margin: 14px 40px;
  position: relative;

  &:first-child a {
    color: #00C000;
  }

  a {
    display: block;
    padding: 14px 0;
    text-decoration: none;
    color: inherit;
    width: 100%;
    height: 100%;
  }

  &:hover > div {
    display: block;
  }
`;

export const SubMenu = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: -188%;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  min-width: 200px;

  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;

  a {
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    font-weight: bold;
    color: #333;
    width: 100%; 
    text-align: center; 
    &:hover {
      background: #f1f1f1;
    }
  }
`;