import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Row, HeaderCell, Cell, TableStyledWrapper, HeadConteiner, HeaderCellContainer, TableContainer, ButtonLink, RadioButtonLabel, ItemImage, ImageWrapper, AddItemButtonContainer, TableMobileContainer, HeaderCellMobileContainer, TableHeaderMobileContainer, InfoItemContainer, PurchaseItemContainer, RowTableMobileContainer, OptionContainer, ItemDetailedInfoContainer, ItemMainInfoContainer, MainInfo, ItemDetails, ToggleButton, ArrowDownIcon, CloseButton, ImageContainerModal, CellImage } from "./Table.styled";
import { Select } from "../../shared-components/Select/Select";
import { InputNumber } from "../../shared-components/InputNumber/InputNumber";
import { AddItemButton } from "../../shared-components/AddItemButton/AddItemButton";
import { useLocation, useNavigate } from "react-router-dom";
import { Pagination } from "../../shared-components/Pagination/Pagination";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Modal from "react-modal"

import { RxCross2 } from "react-icons/rx";
import { BiSolidColorFill } from "react-icons/bi";
import { IoPricetagsOutline } from "react-icons/io5";
import { TbWeight } from "react-icons/tb";
import { FaMagnifyingGlass } from "react-icons/fa6";

import { gardenItemApi } from "../../../api/requests/garden-item.api";
import { GardenItemInterface } from "../../../types/interfaces/GardenItem.interface";
import { Pot } from "../../../types/interfaces/Pot.interface";
import { Loader } from "../../shared-components/Loader/Loader";

interface TableProps {
  gardenId: any;
  searchGardenItemId?: any;
}

function formatString(input: any) {
  if (input === "volume") {
    input = "pots"
  }
  const words = input.split(/(?=[A-Z])/);

  const formattedWords = words.map((word: any, index: any) => {
    if (index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    return word.toLowerCase();
  });

  return formattedWords.join(" ");
}


const exportToPDF = (products: GardenItemInterface[]) => {
  const doc = new jsPDF();

  products.forEach((product, index) => {
    const dataKeys = Object.keys(product.data);
    const fixedDateKeys = dataKeys.map(item => {
      if (item === "height" || item === "treeDiameter") {
        return formatString(item) + " (m)";
      } else return formatString(item);
    });

    const dataValues = dataKeys.map(key => {
      const value = product.data[key];
      return typeof value === 'object' ? JSON.stringify(value) : value || '-';
    });

    const tableHead = [
      ...fixedDateKeys,
      'Volume (l)', 'Quantity', 'Price (£)', 'Weight (kg)'
    ];

    const tableBody = product.pots.map((pot: Pot, potIndex: any) => [
      ...potIndex === 0 ? dataValues : new Array(dataKeys.length).fill(''),
      pot.volume || '-',
      pot.isAvailable ? pot.quantity || '-' : "-",
      pot.price || '-',
      pot.weight || '-',
    ]);

    (doc as any).autoTable({
      head: [tableHead],
      body: tableBody,
      startY: index === 0 ? 20 : (doc as any).autoTable.previous.finalY + 10,
      columnStyles: {
        0: { cellWidth: 32, overflow: 'linebreak' }
      },
      styles: {
        cellPadding: 2,
        fontSize: 9,
        overflow: 'linebreak',
        halign: 'left',
        valign: 'middle'
      },
      margin: { top: 4 }
    });
  });

  doc.save('catalogue all products.pdf');
};


export const Table = (props: TableProps): ReactElement => {
  const { gardenId, searchGardenItemId } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get("page") || "1", 10);
  const initialFilter = queryParams.get("filter") || "none";

  const [itemGardenData, setItemGardenData] = useState<any>(null);
  const [originalItems, setOriginalItems] = useState<any[]>([]);
  const [selectedValue, setSelectedValue] = useState<string>(initialFilter);
  const [sortPots, setSortPots] = useState<string[]>([]);
  const [displayedItems, setDisplayedItems] = useState<any[]>([]);
  const [selectedPot, setSelectedPot] = useState<number | null>(initialFilter === "none" ? null : Number(initialFilter));
  const [selectedPots, setSelectedPots] = useState<string[]>([]);
  const [selectedQuantities, setSelectedQuantities] = useState<number[]>([]);
  const [page, setPage] = useState<number>(initialPage);
  const [openDetails, setOpenDetails] = useState<{ [key: number]: boolean }>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("none");
  const [limit] = useState(10);
  const didRun = useRef(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");
  const [gardenItemsById, setGardenItemsById] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchItems = async () => {
      const page = parseInt(queryParams.get("page") || "1", 10);
      const filter = queryParams.get("filter") || "none";
      const limit = parseInt(queryParams.get("limit") || "6", 10);
      setFilter(filter);
      let itemGardenData;
      if (searchGardenItemId) {
        itemGardenData = await gardenItemApi.getGardenItemfirstInSearch(gardenId, searchGardenItemId);
      } else {
        if (filter === "none" || filter === "None") {
          itemGardenData = await gardenItemApi.getGardenItemPagination(page, limit, gardenId);
        } else {
          itemGardenData = await gardenItemApi.getFilteredGardenItems(page, limit, gardenId, filter);
        }
      }
      setItemGardenData(itemGardenData);
    };

    fetchItems();
  }, [location.search, searchGardenItemId, currentPage, limit, gardenId]);

  useEffect(() => {
    if (itemGardenData) {
      setOriginalItems(itemGardenData.items || []);
      setSelectedPots(Array((itemGardenData.items || []).length).fill(""));
      setSelectedQuantities((itemGardenData.items || []).map((item: any) => (0)));

      const sortedGardenData = [
        ...itemGardenData.items.filter((garden: any) => !isBadObject(garden)),
        ...itemGardenData.items.filter((garden: any) => isBadObject(garden))
      ];

      setDisplayedItems(itemGardenData.items)
      setLoading(false)
    }
  }, [itemGardenData]);

  useEffect(() => {
    const fetchData = async () => {
      if (!didRun.current) {
        const allPots = await gardenItemApi.getGardenItemByGardenId(gardenId);
        const allPotsToMap = allPots.flatMap((item: { pots: any[]; }) => item.pots.map(pot => pot.volume));
        const uniquePots = Array.from(new Set(allPotsToMap));
        const sortedPots = uniquePots.sort((a: any, b: any) => a - b);
        sortedPots.push("None")
        setSortPots(sortedPots.map(String));
        didRun.current = true;
      }
    }

    fetchData();
  }, [originalItems]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = parseInt(queryParams.get("page") || "1", 10);
    const filter = queryParams.get("filter") || "none";

    setSelectedValue(filter);
    setSelectedPot(filter === "none" ? null : Number(filter));
    setPage(page);
  }, [location.search]);

  useEffect(() => {
    setSelectedQuantities((prevSelectedQuantities) =>
      prevSelectedQuantities.map((quantity, index) =>
        selectedPots[index] ? 0 : quantity
      )
    );
  }, [selectedPots]);

  useEffect(() => {
    const fetchData = async () => {
      const allGardenItemById = await gardenItemApi.getGardenItemByGardenId(gardenId)

      setGardenItemsById(allGardenItemById)
    }

    fetchData()

  }, []);

  const isBadObject = (garden: GardenItemInterface): boolean => {
    return garden.pots.every(pot => pot.quantity === 0 || !pot.isAvailable);
  };

  const toggleDropdown = (itemId: any) => {
    setOpenDetails((prevState: { [key: number]: boolean }) => ({
      ...prevState,
      [itemId]: !prevState[itemId]
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedValue(value);
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set("filter", value);
    newSearchParams.set("page", "1");
    navigate(`?${newSearchParams.toString()}`);

    if (value === "none") {
      setSelectedPot(null);
    } else {
      setSelectedPot(Number(value));
    }
  };

  const chunkIntoThree = (arr: string[]) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += 3) {
      chunks.push(arr.slice(i, i + 3));
    }
    return chunks;
  };

  const potChunks = chunkIntoThree(sortPots);


  const handlePotChange = (index: any, value: any) => {
    setSelectedPots((prevSelectedPots) => {
      const newSelectedPots = [...prevSelectedPots];
      newSelectedPots[index] = value.toString();
      return newSelectedPots;
    });
  };

  const handleQuantityChange = (index: number, newValue: number) => {
    setSelectedQuantities((prevSelectedQuantities) => {
      const newSelectedQuantities = [...prevSelectedQuantities];
      newSelectedQuantities[index] = newValue >= 1 ? newValue : 0;
      return newSelectedQuantities;
    });
  };

  const openModal = (src: string) => {
    setModalImageSrc(src);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <TableStyledWrapper>
      {(originalItems.length === 0) ? (
        (loading === true) ?
          <Loader style={{ width: "100%", height: '250px' }} /> :
          <div>No items available in this category.</div>
      ) : (
        <>
          <TableContainer>
            <HeadConteiner>
              <HeaderCellContainer style={{ fontWeight: "bold", cursor: "default", width: "9%", paddingLeft: "24px" }}>
                Sorting
              </HeaderCellContainer>
              <HeaderCellContainer style={{ width: "15%" }}>
                {potChunks.map((chunk, chunkIndex) => (
                  <div key={chunkIndex} style={{ display: "flex", justifyContent: "space-around", minWidth: "90%", marginBottom: "4px" }}>
                    {chunk.map((item, index) => (
                      <RadioButtonLabel key={index} style={{ width: "33%" }}>
                        <input
                          type="radio"
                          value={item}
                          checked={selectedValue === item.toString()}
                          onChange={handleChange}
                        />
                        <span></span>
                        {item}
                      </RadioButtonLabel>
                    ))}
                  </div>
                ))}

              </HeaderCellContainer>
              {Object.keys(originalItems[0].data).map((key, index) =>
                index === 0 ? null : (
                  <HeaderCell key={key}>{formatString(key)}</HeaderCell>
                )
              )}
              {Object.keys(originalItems[0].pots[0]).map((key, index) =>
                key === "isAvailable" || key === "_id" ? null : (
                  <HeaderCell key={key}>{formatString(key)}</HeaderCell>
                )
              )}
              <HeaderCellContainer style={{ width: "18%" }}>
                <ButtonLink onClick={() => exportToPDF(gardenItemsById)} rel="noopener noreferrer">
                  Pdf
                </ButtonLink>
              </HeaderCellContainer>
            </HeadConteiner>
            {displayedItems.map((item: any, index) => (
              <Row key={index}>
                <CellImage style={{ width: "10%", display: "block" }}>
                  <ImageWrapper onClick={() => openModal(item.image.imageUrl)}>
                    <FaMagnifyingGlass />
                    <ItemImage src={item.image.imageUrl} alt="Example" />
                  </ImageWrapper>
                </CellImage>
                {Object.keys(item.data).map((key: string) => {
                  if (key === "name") {
                    return (
                      <Cell
                        key={key}
                        style={{
                          width: "14%",
                          textAlign: "left",
                          justifyContent: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        {item.data[key]}
                      </Cell>
                    );
                  } else if (key === "color") {
                    return <Cell key={key}>{item.data[key]}</Cell>;

                  } else {
                    return <Cell key={key}>{item.data[key]}m</Cell>;
                  }
                })}
                {Object.keys(item.pots[0]).map((key: string) => {
                  if (key === "isAvailable") {
                    return null;
                  } else if (key === "_id") {
                    return null;
                  } else if (key === "volume") {
                    return <Cell key={key}>
                      <Select
                        options={item.pots}
                        selectedIndex={selectedPots[index] ? Number(selectedPots[index]) : 0}
                        onSelect={(value: any) => handlePotChange(index, value)}
                      />
                    </Cell>
                  } else if (key === "quantity") {
                    return <Cell key={key}>
                      <InputNumber
                        value={selectedQuantities[index]}
                        maxValue={item.pots[Number(selectedPots[index])].quantity}
                        isAvailable={item.pots[Number(selectedPots[index])].isAvailable}
                        onChange={(newValue) =>
                          handleQuantityChange(index, newValue)
                        }
                      />
                    </Cell>
                  } else if (key === "price") {
                    return <Cell key={key}>
                      {item.pots[Number(selectedPots[index])].price}£
                    </Cell>
                  } else
                    return <Cell key={key}>
                      {item.pots[Number(selectedPots[index])].weight}kg
                    </Cell>
                })}

                <AddItemButtonContainer>
                  <AddItemButton
                    item={item}
                    selectedPot={Number(selectedPots[index])}
                    quantity={selectedQuantities[index]}
                  />
                </AddItemButtonContainer>
              </Row>
            ))}
            <Pagination
              totalPages={itemGardenData.totalPages}
              currentPage={itemGardenData.page}
              selectedValue={filter}
            />
          </TableContainer>

          <TableMobileContainer>
            <TableHeaderMobileContainer>
              <HeaderCellMobileContainer style={{ width: "56%" }} >
                {potChunks.map((chunk, chunkIndex) => (
                  <div key={chunkIndex} style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
                    {chunk.map((item, index) => (
                      <RadioButtonLabel key={index} style={{ width: "33%" }}>
                        <input
                          type="radio"
                          value={item}
                          checked={selectedValue === item.toString()}
                          onChange={handleChange}
                        />
                        <span></span>
                        {item}
                      </RadioButtonLabel>
                    ))}
                  </div>
                ))}

              </HeaderCellMobileContainer>
              <HeaderCellMobileContainer style={{ width: "40%" }}>
                <ButtonLink onClick={() => exportToPDF(gardenItemsById)} rel="noopener noreferrer">
                  Pdf
                </ButtonLink>
              </HeaderCellMobileContainer>
            </TableHeaderMobileContainer>

            {displayedItems.map((item: any, index) => (
              <RowTableMobileContainer key={index}>

                <InfoItemContainer>
                  <ItemMainInfoContainer>
                    <ImageWrapper>
                      <FaMagnifyingGlass />
                      <ItemImage src={item.image.imageUrl} alt="Example" onClick={() => openModal(item.image.imageUrl)}
                      />
                    </ImageWrapper>
                    <MainInfo>
                      <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                        {item.data.name}
                      </div>
                      <div style={{ display: "flex", flexDirection: "row", marginLeft: "4px", marginTop: "6px", justifyContent: "left" }}>
                        <BiSolidColorFill style={{ marginRight: "4px" }} /> {item.data.color}
                      </div>
                      <div style={{ display: "flex", flexDirection: "row", marginLeft: "4px", marginTop: "6px", justifyContent: "left" }}>
                        <IoPricetagsOutline style={{ marginRight: "4px" }} /> <div style={{ color: "#00C000" }}>{item.pots[Number(selectedPots[index])].price}£</div>
                      </div>
                    </MainInfo>
                  </ItemMainInfoContainer>
                  <div style={{ display: "flex", flexDirection: "row", marginTop: "6px", }}>
                    <TbWeight style={{ marginRight: "4px" }} /><div>of 1 item:  {item.pots[Number(selectedPots[index])].weight} kg</div>
                  </div>
                  <ItemDetailedInfoContainer>
                    {Object.keys(item.data).length > 2 ?
                      <ToggleButton onClick={() => toggleDropdown(item.id)}>
                        <ArrowDownIcon style={{ cursor: "pointer" }} size={20} />       {'Details'}
                      </ToggleButton> : null}


                    <ItemDetails isOpen={openDetails[item.id]}>
                      {Object.keys(item.data).map((key: string) => {
                        if (key === "name") {
                          return null;
                        } else if (key === "color") {
                          return null;
                        } else {
                          return <div style={{ display: "flex", justifyContent: "left", marginTop: "2px" }}
                            key={key}>{formatString(key) + ": " + item.data[key]}m</div>;
                        }
                      })}
                    </ItemDetails>

                  </ItemDetailedInfoContainer>
                </InfoItemContainer>


                <PurchaseItemContainer>
                  <OptionContainer>
                    <Select
                      options={item.pots}
                      selectedIndex={selectedPots[index] ? Number(selectedPots[index]) : 0}
                      onSelect={(value: any) => handlePotChange(index, value)}
                    />
                    <InputNumber
                      value={selectedQuantities[index]}
                      maxValue={item.pots[Number(selectedPots[index])].quantity}
                      isAvailable={item.pots[Number(selectedPots[index])].isAvailable}
                      onChange={(newValue) =>
                        handleQuantityChange(index, newValue)
                      }
                    />
                  </OptionContainer>
                  <AddItemButtonContainer>
                    <AddItemButton
                      item={item}
                      selectedPot={Number(selectedPots[index])}
                      quantity={selectedQuantities[index]}
                    />
                  </AddItemButtonContainer>

                </PurchaseItemContainer>


              </RowTableMobileContainer>
            ))}
            <Pagination
              totalPages={itemGardenData.totalPages}
              currentPage={itemGardenData.page}
              selectedValue={filter}
            />
          </TableMobileContainer>
        </>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        ariaHideApp={false}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            maxWidth: "90%",
            maxHeight: "80%",
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <div>
          <CloseButton onClick={closeModal}><RxCross2 size={30} /></CloseButton>
          <ImageContainerModal >
            <img src={modalImageSrc} alt="Enlarged Example" style={{ width: "100%", height: "100%" }} />
          </ImageContainerModal>
        </div>
      </Modal>
    </TableStyledWrapper>
  );
};