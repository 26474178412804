import React, { ReactElement, useEffect } from "react";


import { CancellationContainer, CancellationInfo, CancellationStyledWrapper, CancellationTitle } from "./Cancellation.styled";

interface CancellationPageProps { }

export const CancellationPage = (props: CancellationPageProps): ReactElement => {
  const { } = props;


  useEffect(() => {
    document.title = `Cancellation : A&O Plants`;
  }, []);


  return (
    <CancellationStyledWrapper>
      <CancellationContainer>
        <CancellationInfo>
          <CancellationTitle>Work in progress...</CancellationTitle>
        </CancellationInfo>
      </CancellationContainer>
    </CancellationStyledWrapper>
  );
};
