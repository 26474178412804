import { HelpRequestInterface } from "../../types/interfaces/HelpRequest.interface";
import { httpClient } from "../httpClient/HttpClient";

export class HelpRequestApi {
  private HELPREQUEST_ENDPOINT = "help-request";

  getHelpRequest = async (): Promise<any> => {
    const data = await httpClient.get(`${this.HELPREQUEST_ENDPOINT}/`);

    return data;
  };

  createHelpRequest = async (helpRequest: HelpRequestInterface): Promise<any> => {
    const data = await httpClient.post(`${this.HELPREQUEST_ENDPOINT}/new`, helpRequest);

    return data;
  };
}

export const helpRequestApi = new HelpRequestApi();
