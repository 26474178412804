import styled from "styled-components";

export const AppStyledWrapper = styled.div`
  width: 100%;
`;

export const AppContentStyledWrapper = styled.div`
  width: 100%;
  margin-top: 120px;
  background-color: #f5f5f5;
  padding: 0 10%;

  @media screen and (max-width: 1440px) {
    padding: 0 6%; 
    margin-top: 100px; 
    font-size: 14px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 86px;
    padding: 0 4%;
  }
`;

export const ContentMenuContainer = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
