import React, { ReactElement } from "react";


import { NotFoundPageStyledWrapper } from "./NotFoundPage.styled";

interface NotFoundPageProps {}

export const NotFoundPage = (props: NotFoundPageProps): ReactElement => {
  const {} = props;

  return (
        <NotFoundPageStyledWrapper>
        404 Page Not Found
        </NotFoundPageStyledWrapper>
  );
};
