import React, { ReactElement } from "react";

import { LogoImage, LogoImageContainer, LogoInfo, LogoStyledWrapper } from "./Logo.styled";
import { BascetLink } from "../Navigation/Navigation.styled";

interface LogoProps { }

export const Logo = (props: LogoProps): ReactElement => {
  const { } = props;

  // function handlerClick(): void {
  //   window.open("/", "_self");
  // }

  return (
    <LogoStyledWrapper >
      <BascetLink to="/">
        <LogoImageContainer>
          <LogoImage src={"/logo-garden.png"} alt="Logo" width={44} height={61} />
        </LogoImageContainer>
        <LogoInfo style={{
        }}>A&O Plants</LogoInfo>
      </BascetLink>
    </LogoStyledWrapper>
  );
};
