import React, { ReactElement, useEffect, useState } from "react";

import { SignupFormStyledWrapper } from "./SignupForm.styled";
import { Input } from "../../shared-components/Input/Input";
import { Button } from "../../shared-components/Button/Button";
import { authApi } from "../../../api/requests/auth.api";
import { PhoneConfirmationModal } from "../../shared-components/PhoneConfirmationModal/PhoneConfirmationModal";
import { localStorageUtils } from "../../../utils/browser-utils/LocalStorage";
import { observer } from "mobx-react-lite";
import { useUserStore } from "../../../hooks/use-user-store-hook/useUserStore";
import { useNavigate } from "react-router-dom";

const errorMessagesMap: Record<string, Record<string, string>> = {
  name: {
    NAME_IS_EMPTY: "Name is empty",
    NAME_IS_TOO_SHORT: "Name is too short",
    NAME_IS_TOO_LONG: "Name is too long",
  },
  phone: {
    PHONE_IS_EMPTY: "Phone is empty",
    PHONE_IS_NOT_VALID: "Phone is not valid",
  },
  email: {
    EMAIL_IS_NOT_VALID: "Email is not valid",
  },
  address: {
    ADDRESS_IS_TOO_SHORT: "Name of organization is too short",
    ADDRESS_IS_TOO_LONG: "Name of organization is too long",
  },
  postCode: {
    POST_CODE_IS_EMPTY: "Post code is empty",
    POST_CODE_IS_NOT_VALID: "Post code is not valid",
  },
  numberVAT: {
    NUMBER_VAT_IS_NOT_VALID: "Number VAT is not valid",
  },
  code: {
    CODE_IS_INVALID: "Code is invalid",
  },
};

const validateName = (name: string): string | null => {
  if (!name) return "NAME_IS_EMPTY";
  if (name.length < 3) return "NAME_IS_TOO_SHORT";
  if (name.length > 15) return "NAME_IS_TOO_LONG";
  return null;
};

const validateNumberVAT = (numberVAT: string): string | null => {
  const regex = /^[0-9]{9}$|^[0-9]{12}$/;
  if (numberVAT === "") return null;
  if (!regex.test(numberVAT)) return "NUMBER_VAT_IS_NOT_VALID";
  return null;
};

const validatePostCode = (postCode: string): string | null => {
  const regex = /^([A-Z]{1,2}[0-9][A-Z0-9]?|[A-Z][0-9]{2}[A-Z]?|[A-Z]{1,2}[0-9]{2}[A-Z]?|[A-Z][0-9][A-Z][0-9][A-Z]?|[A-Z][0-9]{2}[A-Z]{2}|[A-Z]{2}[0-9]{2})\s?[0-9][A-Z]{2}$/i;
  if (postCode === "") return "POST_CODE_IS_EMPTY"; 
  if (!regex.test(postCode)) return "POST_CODE_IS_NOT_VALID";
  return null;
};

export const SignupForm = observer((): ReactElement => {
  const { setUser } = useUserStore();
  const navigate = useNavigate();

  const [signUpForm, setSignUpForm] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    postCode: "",
    numberVAT: "",
  });

  const [code, setCode] = useState("");
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {

  }, [signUpForm]);

  const handleInputChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setSignUpForm({
        ...signUpForm,
        [key]: e.target.value,
      });
    };

  const handleStartRegister = async () => {
    const newErrors: string[] = [];

    const nameError = validateName(signUpForm.name);
    const numberVATError = validateNumberVAT(signUpForm.numberVAT);
    const postCodeError = validatePostCode(signUpForm.postCode);

    if (nameError) newErrors.push(nameError);
    if (numberVATError) newErrors.push(numberVATError);
    if (postCodeError) newErrors.push(postCodeError);

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }

    const data = await authApi.startSignUp(signUpForm.phone, true);
    const { isStarted, errors: apiErrors } = data;

    if (apiErrors && apiErrors?.length) {
      setErrors(apiErrors);

      return;
    }

    if (isStarted) {
      setIsConfirmationOpen(true);
    }
  };

  const handleRegister = async () => {

    const isCodeValid = !code.split("").includes("·");

    if (!isCodeValid) {
      setErrors((prevErrors: any) => [...prevErrors, "CODE_IS_INVALID"]);
      return;
    }

    const {
      expiresIn,
      refreshToken,
      refreshTokenExpiresIn,
      token,
      user,
      errors,
    } = await authApi.signUp({
      ...signUpForm,
      code,
      isAccepted: true,
    });

    if (errors && errors?.length) {
      setErrors(errors);
      setIsConfirmationOpen(true);
      return;
    }

    localStorageUtils.setAllAuthUserInfo({
      expiresIn,
      refreshToken,
      refreshTokenExpiresIn,
      token,
    });

    setUser(user);

    navigate("/");
  };

  const handleCodeChange = (code: string) => {
    setCode(code);
  };

  return (
    <SignupFormStyledWrapper>
      <Input
        placeholder="Name*"
        type="text"
        onChange={handleInputChange("name")}
        value={signUpForm.name}
        isValid={
          !Boolean(
            errors.includes("NAME_IS_EMPTY") ||
            errors.includes("NAME_IS_TOO_SHORT") ||
            errors.includes("NAME_IS_TOO_LONG")
          )
        }
        errorMessage={errors.reduce((acc: string[], error: string) => {
          if (errorMessagesMap.name[error]) {
            acc.push(errorMessagesMap.name[error]);
          }

          return acc;
        }, [])}
      />
      <Input
        placeholder="Phone*"
        type="text"
        onChange={handleInputChange("phone")}
        value={signUpForm.phone}
        isValid={
          !Boolean(
            errors.includes("PHONE_IS_EMPTY") ||
            errors.includes("PHONE_IS_NOT_VALID")
          )
        }
        errorMessage={errors.reduce((acc: string[], error: string) => {
          if (errorMessagesMap.phone[error]) {
            acc.push(errorMessagesMap.phone[error]);
          }

          return acc;
        }, [])}
      />
      <Input
        placeholder="Email"
        type="text"
        onChange={handleInputChange("email")}
        value={signUpForm.email}
        isValid={!Boolean(errors.includes("EMAIL_IS_NOT_VALID"))}
        errorMessage={errors.reduce((acc: string[], error: string) => {
          if (errorMessagesMap.email[error]) {
            acc.push(errorMessagesMap.email[error]);
          }

          return acc;
        }, [])}
      />
      <Input
        placeholder="Name of organization"
        type="text"
        onChange={handleInputChange("address")}
        value={signUpForm.address}
        isValid={
          !Boolean(
            errors.includes("ADDRESS_IS_TOO_SHORT") ||
            errors.includes("ADDRESS_IS_TOO_LONG")
          )
        }
        errorMessage={errors.reduce((acc: string[], error: string) => {
          if (errorMessagesMap.address[error]) {
            acc.push(errorMessagesMap.address[error]);
          }

          return acc;
        }, [])}
      />
      <Input
        placeholder="Post code*"
        type="text"
        onChange={handleInputChange("postCode")}
        value={signUpForm.postCode}
        isValid={
          !Boolean(
            errors.includes("POST_CODE_IS_EMPTY") ||
            errors.includes("POST_CODE_IS_NOT_VALID")
          )
        }
        errorMessage={errors.reduce((acc: string[], error: string) => {
          if (errorMessagesMap.postCode[error]) {
            acc.push(errorMessagesMap.postCode[error]);
          }

          return acc;
        }, [])}
      />
      <Input
        placeholder="Number VAT"
        type="text"
        onChange={handleInputChange("numberVAT")}
        value={signUpForm.numberVAT}
        isValid={!Boolean(errors.includes("NUMBER_VAT_IS_NOT_VALID"))}
        errorMessage={errors.reduce((acc: string[], error: string) => {
          if (errorMessagesMap.numberVAT[error]) {
            acc.push(errorMessagesMap.numberVAT[error]);
          }

          return acc;
        }, [])}
      />

      <Button label="Register" onClick={handleStartRegister} />

      <PhoneConfirmationModal
        phone={signUpForm.phone}
        modalTitle="Registration"
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onSubmit={handleRegister}
        onCodeChange={handleCodeChange}
        isCodeValid={errors.includes("CODE_IS_INVALID") || errors.includes("CODE_IS_NOT_VALID")}
      />
    </SignupFormStyledWrapper>
  );
});
