import React, { ReactElement, useEffect, useRef, useState } from "react";

import { DropdownArrow, OptionsContainer, Option, SelectContainer, SelectHeader, SelectStyledWrapper, MobileTitleContainer } from "./Select.styled";
import { IoIosArrowDown } from "react-icons/io";

interface SelectProps {
  options: any;
  selectedIndex: any;
  onSelect: any;
}

export const Select = (props: SelectProps): ReactElement => {
  const { options, selectedIndex, onSelect } = props;
  const [isOpen, setIsOpen] = useState(false);

  const selectRef = useRef<HTMLDivElement>(null);

  const allPotsToMap = options.map((option: { volume: any; }) => option.volume);
  
  const handleSelect = (index: any) => {
    onSelect(index);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <SelectStyledWrapper ref={selectRef}>
      <MobileTitleContainer >
        Pots
      </MobileTitleContainer>
      <SelectContainer>
        <SelectHeader onClick={() => setIsOpen(!isOpen)}>
        {allPotsToMap.length > 0 ? `${allPotsToMap[selectedIndex]}l` : "No pots available"}
        <DropdownArrow>
            <IoIosArrowDown size={20} />
          </DropdownArrow>
        </SelectHeader>
        <OptionsContainer isOpen={isOpen}>
          {allPotsToMap.map((option: any, index: any) => (
            <Option key={index} onClick={() => handleSelect(index)}>
              {option}l
            </Option>
          ))}
        </OptionsContainer>
      </SelectContainer>
    </SelectStyledWrapper>
  );
};
