import React, { ReactElement } from "react";

import { PaginationButton, PaginationContainer, PaginationEllipsis, PaginationStyledWrapper } from "./Pagination.styled";
import { useLocation, useNavigate } from "react-router-dom";

interface PaginationProps {
  totalPages: number;
  currentPage: any;
  selectedValue: string;
}

export const Pagination = ({ totalPages, currentPage, selectedValue }: PaginationProps): ReactElement => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo(0, 0); 
  };

  const handlePageChange = (pageNumber: number) => {
    navigate(`?filter=${selectedValue}&page=${pageNumber}`);
    scrollToTop();
  };

  const renderPagination = () => {
    const pages = [];

    const addPage = (pageNumber: number) => {
      pages.push(
        <PaginationButton
          key={pageNumber}
          onClick={() => handlePageChange(pageNumber)}
          disabled={pageNumber === currentPage}
        >
          {pageNumber}
        </PaginationButton>
      );
    };

    if (currentPage > 1) {
      pages.push(
        <PaginationButton
          key="prev"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          {"<"}
        </PaginationButton>
      );
    }

    addPage(1);
    if (currentPage > 4) {
      pages.push(
        <PaginationEllipsis key="startEllipsis">...</PaginationEllipsis>
      );
    }

    for (let i = currentPage - 2; i <= currentPage + 2; i++) {
      if (i > 1 && i < totalPages) {
        addPage(i);
      }
    }

    if (currentPage < totalPages - 3) {
      pages.push(<PaginationEllipsis key="endEllipsis">...</PaginationEllipsis>);
    }
    if (totalPages > 1) {
      addPage(totalPages);
    }

    if (currentPage < totalPages) {
      pages.push(
        <PaginationButton
          key="next"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          {">"}
        </PaginationButton>
      );
    }

    return pages;
  };

  return <PaginationContainer>{renderPagination()}</PaginationContainer>;
};