import styled from "styled-components";
import { contentWidth, mobile } from "../../styles/variables.styled";

export const HomeStyledWrapper = styled.div`
  padding:0 0 10px 0;
  display: flex;
  justify-content: center;
  width: 100%;

@media screen and (max-width: 768px) {
  width: 100%;
  
  }

`;

export const HomeContainer = styled.div`
  width: 1440px;
  @media screen and (max-width: 768px) {
  width: 100%;
  
  }
`;

export const HomeTitle = styled.div`
  height: 200px;
  width: auto;
  background-image: url("/title-image-background.jpg");
  background-position: center;
  background-size: cover;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
  width: 100%;
  height: 85px;
  
  }
`;

export const TitleText = styled.p`
  color: #fff;
  font-size: 50px;
  font-weight: bold;

  @media screen and (max-width: 768px) {
  font-size: 22px;
  text-align: center;
  }
`;

export const CatalogeFiles = styled.div`
  background-color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  margin: 10px 0;
  border-radius: 4px;

@media screen and (max-width: 768px) {
  width: 100%;
  padding: 0;
  margin: 0;

  }

`;

export const DownloadTitle = styled.div`
  margin: 0 40px;
  text-transform: uppercase; 

  @media screen and (max-width: 768px) {
    font-size: 14px;
    text-transform: none; 
    white-space: nowrap;
    margin: 0 15px;
    
    }

    @media screen and (max-width: 390px) {
    font-size: 13px;
    text-transform: none; 
    white-space: nowrap;
    margin: 0 3%;

    }
`;

export const ButtonDownloadFile = styled.div`
  background-color: #CCF2CC; 
  color: black; 
  padding: 10px 20px; 
  border: none; 
  cursor: pointer; 
  margin-right: 20px;
  
  &:hover {
    background-color: #A3D9A5; 
  }
  
  @media screen and (max-width: 768px) {
  padding: 0;
  margin-right: 10px;
  padding: 11px 6px; 
  font-size: 14px;
  white-space: nowrap
  }
  
  @media screen and (max-width: 390px) {
  padding: 0;
  margin-right: 3%;
  padding: 11px 6px; 
  font-size: 13px;
  white-space: nowrap
  }
`;

export const PlantsContainer = styled.div`
  margin: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin:0;

  }

`;


export const PlantItem = styled.div<{ imgurl: string }>`
  width: 18%;
  height: 250px;
  background-image: url(${props => props.imgurl});
  background-size: cover;
  background-position: center;
  border-radius: 4px;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 90px;
    margin-top: 15px;
  }
`;


export const PlantTitle = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height:20%;
  background-color:#FFFFFFD8;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    height:30px;


  }
`;

export const PlantButtonContainer = styled.div`
  height: 20%;
  margin-top: 140px;
  display: flex;
  justify-content:center;
  align-items:center;

@media screen and (max-width: 768px) {
  margin-top: 32px;


  }
`;


export const PlantButton = styled.div`
  width: 78%;
  height: 42px;
  background: #00C000;
  border: 1px solid #00C000;
  border-radius: 2px;
  cursor: pointer; 
  display: flex;
  justify-content:center;
  align-items:center;
  color: #fff;

@media screen and (max-width: 768px) {
  height: 22px;
  width: 40%;
  border-radius: 4px;


  }
`;

export const AboutUsContainer = styled.div`
  background-color: #fff;
  width: 100%;
  height: 405px;
  border-radius: 4px;

@media screen and (max-width: 768px) {
  height: auto;

  }
`;

export const AboutUsTitle = styled.div`
display: flex;
justify-content:center;
align-items:center;
padding-top: 50px;
margin-bottom: 40px;
font-size: 24px;
font-weight: bold;

@media screen and (max-width: 768px) {
  margin-bottom: 0;
  font-size: 18px;

  }
`;

export const AboutUsInfoContainer = styled.div`
display: flex;
justify-content: space-between;
margin: 0 21%;
gap: 10px;

@media screen and (max-width: 1440px) {
  margin: 0 12%;
  }

@media screen and (max-width: 768px) {
  flex-direction: column;
  margin: 0 8%;
  align-items: center

  }

`;

export const AboutUsInfo = styled.div`
width: 404px;
height: 270px;
line-height: 22px;
overflow: hidden;
display: flex;
justify-content:center;
align-items:center;

@media screen and (max-width: 768px) {
  width: 100%;
  height: auto;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  margin: 18px 0;
}

`;

export const AboutUsImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; 

  @media screen and (max-width: 768px) {
    height: 170px;
    object-position: center 60%;
    }
`;

export const HowOrderContainer = styled.div`
background-color: #CCF2CC;
margin: 10px 0;
`;

export const OrderTitle = styled.div`
font-weight: bold;
display: flex;
justify-content: center;
align-items: center;
padding: 50px 0 30px 0;
font-size: 24px;

@media screen and (max-width: 768px) {
  font-size: 18px;
  padding: 30px 0 10px 0;

}
`;

export const OrderInfo = styled.div`
  line-height: 22px;
  padding: 0 22% 60px 22%;
  display: flex;
  flex-direction: column;
  gap: 10px;

@media screen and (max-width: 768px) {
    font-size: 12px;
    text-align: center;
    padding: 0 12% 20px 12%;
  }
`;

export const AccardioneContainer = styled.div`

`;

export const SearchMobileContainer = styled.div`
display: none;

@media screen and (max-width: 768px) {
 display: flex;
 
  }
`;
