import styled from "styled-components";
import { contentWidth, mobile } from "../../styles/variables.styled";

export const AboutStyledWrapper = styled.div`
    width:100%;
    display: flex;
    justify-content: center;
`;

export const AboutContainer = styled.div`
    width: ${contentWidth};
    @media screen and (max-width: 768px) {
  width: 100%;
  
  }
`;

export const AboutDetailsContainer = styled.div`
    background-color: #fff;
    display:flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 10px 0;
@media screen and (max-width: ${mobile}) {
    margin: 10px 0;
}
`;

export const AboutTitle = styled.p`
    display:flex;
    justify-content: center;
    font-size: 24px;
    padding: 40px 0 30px 0;
    font-weight: bold;

    @media screen and (max-width: ${mobile}) {
        padding: 20px 0 15px 0;
    }

`;

export const AboutUsInfoContainer = styled.div`
display: flex;
justify-content: space-between;
margin: 0 19%;
padding: 0 0 50px 0;
gap:10px;

@media screen and (max-width: ${mobile}) {
  flex-direction: column;
  margin: 0 8%;
  align-items: center

  }

`;

export const AboutUsInfo = styled.div`
width: 430px;
height: 270px;
line-height: 22px;
overflow: hidden;
display: flex;
justify-content:center;
align-items:center;

@media screen and (max-width: ${mobile}) {
  width: 100%;
  height: auto;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  margin: 18px 0;
}

`;

export const AboutUsImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; 

  @media screen and (max-width: ${mobile}) {
    height: 170px;
    object-position: center 60%;
    }
`;