import { IoIosArrowDown } from "react-icons/io";
import styled from "styled-components";

export const SelectStyledWrapper = styled.div`
  position: relative;
  width: 100px;

  @media screen and (max-width: 1440px) {
    width: 70px;
  }


  @media screen and (max-width: 768px) {
    width: 46%;

  }`;

export const SelectContainer = styled.div`
position: relative;
  @media screen and (max-width: 768px) {

  }
`;

export const SelectHeader = styled.div`
  background-color: #CCF2CC;
  padding: 14px 12px 14px 20px;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  @media screen and (max-width: 1440px) {
    padding: 12px 5px 12px 10px;
  }

  @media screen and (max-width: 768px) {
    padding: 7px 6px 7px 10px;

  }
`;

interface OptionsContainerProps {
    isOpen: boolean;
  }
  
  export const OptionsContainer = styled.div<OptionsContainerProps>`
    position: absolute;
    top: calc(100%);
    left: 0;
    width: 100%;
    background-color: #CCF2CC;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    display: ${(props) => (props.isOpen ? "block" : "none")};


  `;

export const Option = styled.div`
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #A3D9A5;
  }

  &:first-of-type {
    border-top: 2px solid #00C000;
  }
`;

export const DropdownArrow = styled.span`
  position: absolute;
  right: 36px;
  width: 0;
  height: 0;

  @media screen and (max-width: 1440px) {
    right: 26px;
  }

  @media (max-width: 768px) {
    right: 26px;
  }
`;


export const ArrowIcon = styled(IoIosArrowDown)`
  size: 20px;

  @media (max-width: 768px) {
    size: 18px; 
  }
`;


export const MobileTitleContainer = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: block;
    font-size: 18px; 
    padding-bottom: 8px;
  }
`;