import { createContext, useContext } from "react";

import { UserStore } from "../../store/UserStore";
import { BascetStore } from "../../store/BascetStore";

export enum StoresEnum {
  USER_STORE = "userStore",
  BASCET_STORE = "bascetStore",

}

export interface StoreInterface {
  userStore: UserStore;
  bascetStore: BascetStore;

}

export const store: StoreInterface = {
  [StoresEnum.USER_STORE]: new UserStore(),
  [StoresEnum.BASCET_STORE]: new BascetStore(),
};


export const StoreContext = createContext(store);

export const useStore = (store: StoresEnum) => {
  const stores: StoreInterface = useContext(StoreContext);

  return stores[store];
};
