import React, { ReactElement, useEffect, useState } from "react";

import { CloseButton, GalleryBlockContainer, GalleryContainer, GalleryContent, GalleryImage, GalleryImageBlock, GalleryImageContainer, GalleryStyledWrapper, GalleryTitle, ImageBigContainer, ImageContainerModal, ImageSmallContiner } from "./Gallery.styled";
import Modal from "react-modal"
import { RxCross2 } from "react-icons/rx";

interface GalleryPageProps { }

export const GalleryPage = (props: GalleryPageProps): ReactElement => {
  const { } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");

  useEffect(() => {
    document.title = `Gallery : A&O Plants`;
  }, []);


  const openModal = (src: string) => {
    setModalImageSrc(src);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <GalleryStyledWrapper>
      <GalleryContainer>
        <GalleryContent>
          <GalleryTitle>Gallery</GalleryTitle>
          <GalleryImageContainer>
            <GalleryImageBlock>
              <GalleryBlockContainer>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-1.jpg")}>
                  <GalleryImage src={"gallery-image/image-1.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-2.jpg")}>
                  <GalleryImage src={"gallery-image/image-2.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>

              <GalleryBlockContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-3.jpg")}>
                  <GalleryImage src={"gallery-image/image-3.jpg"} />
                </ImageSmallContiner>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-4.jpg")}>
                  <GalleryImage src={"gallery-image/image-4.jpg"} />
                </ImageBigContainer>
              </GalleryBlockContainer>

              <GalleryBlockContainer className="third-block">
                <ImageBigContainer onClick={() => openModal("gallery-image/image-5.jpg")}>
                  <GalleryImage src={"gallery-image/image-5.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-6.jpg")}>
                  <GalleryImage src={"gallery-image/image-6.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>
            </GalleryImageBlock>

            <GalleryImageBlock>
              <GalleryBlockContainer>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-7.jpg")}>
                  <GalleryImage src={"gallery-image/image-7.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-8.jpg")}>
                  <GalleryImage src={"gallery-image/image-8.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>

              <GalleryBlockContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-9.jpg")}>
                  <GalleryImage src={"gallery-image/image-9.jpg"} />
                </ImageSmallContiner>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-10.jpg")}>
                  <GalleryImage src={"gallery-image/image-10.jpg"} />
                </ImageBigContainer>
              </GalleryBlockContainer>

              <GalleryBlockContainer className="third-block">
                <ImageBigContainer onClick={() => openModal("gallery-image/image-11.jpg")}>
                  <GalleryImage src={"gallery-image/image-11.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-12.jpg")}>
                  <GalleryImage src={"gallery-image/image-12.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>
            </GalleryImageBlock>

            <GalleryImageBlock>
              <GalleryBlockContainer>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-13.jpg")}>
                  <GalleryImage src={"gallery-image/image-13.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-14.jpg")}>
                  <GalleryImage src={"gallery-image/image-14.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>

              <GalleryBlockContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-15.jpg")}>
                  <GalleryImage src={"gallery-image/image-15.jpg"} />
                </ImageSmallContiner>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-16.jpg")}>
                  <GalleryImage src={"gallery-image/image-16.jpg"} />
                </ImageBigContainer>
              </GalleryBlockContainer>

              <GalleryBlockContainer className="third-block">
                <ImageBigContainer onClick={() => openModal("gallery-image/image-17.jpg")}>
                  <GalleryImage src={"gallery-image/image-17.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-18.jpg")}>
                  <GalleryImage src={"gallery-image/image-18.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>
            </GalleryImageBlock>

            <GalleryImageBlock>
              <GalleryBlockContainer>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-19.jpg")}>
                  <GalleryImage src={"gallery-image/image-19.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-20.jpg")}>
                  <GalleryImage src={"gallery-image/image-20.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>

              <GalleryBlockContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-21.jpg")}>
                  <GalleryImage src={"gallery-image/image-21.jpg"} />
                </ImageSmallContiner>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-22.jpg")}>
                  <GalleryImage src={"gallery-image/image-22.jpg"} />
                </ImageBigContainer>
              </GalleryBlockContainer>

              <GalleryBlockContainer className="third-block">
                <ImageBigContainer onClick={() => openModal("gallery-image/image-23.jpg")}>
                  <GalleryImage src={"gallery-image/image-23.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-24.jpg")}>
                  <GalleryImage src={"gallery-image/image-24.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>
            </GalleryImageBlock>

            <GalleryImageBlock>
              <GalleryBlockContainer>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-25.jpg")}>
                  <GalleryImage src={"gallery-image/image-25.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-26.jpg")}> 
                  <GalleryImage src={"gallery-image/image-26.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>

              <GalleryBlockContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-27.jpg")}>
                  <GalleryImage src={"gallery-image/image-27.jpg"} />
                </ImageSmallContiner>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-28.jpg")}>
                  <GalleryImage src={"gallery-image/image-28.jpg"} />
                </ImageBigContainer>
              </GalleryBlockContainer>

              <GalleryBlockContainer className="third-block">
                <ImageBigContainer onClick={() => openModal("gallery-image/image-29.jpg")}>
                  <GalleryImage src={"gallery-image/image-29.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-30.jpg")}>
                  <GalleryImage src={"gallery-image/image-30.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>
            </GalleryImageBlock>

            <GalleryImageBlock>
              <GalleryBlockContainer>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-31.jpg")}>
                  <GalleryImage src={"gallery-image/image-31.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-32.jpg")}>
                  <GalleryImage src={"gallery-image/image-32.jpg"} />
                </ImageSmallContiner >
              </GalleryBlockContainer>

              <GalleryBlockContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-33.jpg")}>
                  <GalleryImage src={"gallery-image/image-33.jpg"} />
                </ImageSmallContiner>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-34.jpg")}>
                  <GalleryImage src={"gallery-image/image-34.jpg"} />
                </ImageBigContainer>
              </GalleryBlockContainer>

              <GalleryBlockContainer className="third-block">
                <ImageBigContainer onClick={() => openModal("gallery-image/image-35.jpg")}>
                  <GalleryImage src={"gallery-image/image-35.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-36.jpg")}>
                  <GalleryImage src={"gallery-image/image-36.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>
            </GalleryImageBlock>

            <GalleryImageBlock>
              <GalleryBlockContainer>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-37.jpg")}>
                  <GalleryImage src={"gallery-image/image-37.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-38.jpg")}>
                  <GalleryImage src={"gallery-image/image-38.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>

              <GalleryBlockContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-39.jpg")}>
                  <GalleryImage src={"gallery-image/image-39.jpg"} />
                </ImageSmallContiner>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-40.jpg")}>
                  <GalleryImage src={"gallery-image/image-40.jpg"} />
                </ImageBigContainer>
              </GalleryBlockContainer>

              <GalleryBlockContainer className="third-block">
                <ImageBigContainer onClick={() => openModal("gallery-image/image-41.jpg")}>
                  <GalleryImage src={"gallery-image/image-41.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-42.jpg")}>
                  <GalleryImage src={"gallery-image/image-42.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>
            </GalleryImageBlock>

            <GalleryImageBlock>
              <GalleryBlockContainer>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-43.jpg")}>
                  <GalleryImage src={"gallery-image/image-43.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-44.jpg")}>
                  <GalleryImage src={"gallery-image/image-44.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>

              <GalleryBlockContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-45.jpg")}>
                  <GalleryImage src={"gallery-image/image-45.jpg"} />
                </ImageSmallContiner>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-46.jpg")}>
                  <GalleryImage src={"gallery-image/image-46.jpg"} />
                </ImageBigContainer>
              </GalleryBlockContainer>

              <GalleryBlockContainer className="third-block">
                <ImageBigContainer onClick={() => openModal("gallery-image/image-47.jpg")}>
                  <GalleryImage src={"gallery-image/image-47.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-48.jpg")}>
                  <GalleryImage src={"gallery-image/image-48.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>
            </GalleryImageBlock>

            <GalleryImageBlock>
              <GalleryBlockContainer>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-49.jpg")}>
                  <GalleryImage src={"gallery-image/image-49.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-50.jpg")}>
                  <GalleryImage src={"gallery-image/image-50.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>

              <GalleryBlockContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-51.jpg")}>
                  <GalleryImage src={"gallery-image/image-51.jpg"} />
                </ImageSmallContiner>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-52.jpg")}>
                  <GalleryImage src={"gallery-image/image-52.jpg"} />
                </ImageBigContainer>
              </GalleryBlockContainer>

              <GalleryBlockContainer className="third-block">
                <ImageBigContainer onClick={() => openModal("gallery-image/image-53.jpg")}>
                  <GalleryImage src={"gallery-image/image-53.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-54.jpg")}>
                  <GalleryImage src={"gallery-image/image-54.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>
            </GalleryImageBlock>

            <GalleryImageBlock>
              <GalleryBlockContainer>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-55.jpg")}>
                  <GalleryImage src={"gallery-image/image-55.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-56.jpg")}>
                  <GalleryImage src={"gallery-image/image-56.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>

              <GalleryBlockContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-57.jpg")}>
                  <GalleryImage src={"gallery-image/image-57.jpg"} />
                </ImageSmallContiner>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-58.jpg")}>
                  <GalleryImage src={"gallery-image/image-58.jpg"} />
                </ImageBigContainer>
              </GalleryBlockContainer>

              <GalleryBlockContainer className="third-block">
                <ImageBigContainer onClick={() => openModal("gallery-image/image-59.jpg")}>
                  <GalleryImage src={"gallery-image/image-59.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-60.jpg")}>
                  <GalleryImage src={"gallery-image/image-60.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>
            </GalleryImageBlock>

            <GalleryImageBlock>
              <GalleryBlockContainer>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-61.jpg")}>
                  <GalleryImage src={"gallery-image/image-61.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-62.jpg")}>
                  <GalleryImage src={"gallery-image/image-62.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>

              <GalleryBlockContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-63.jpg")}>
                  <GalleryImage src={"gallery-image/image-63.jpg"} />
                </ImageSmallContiner>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-64.jpg")}>
                  <GalleryImage src={"gallery-image/image-64.jpg"} />
                </ImageBigContainer>
              </GalleryBlockContainer>

              <GalleryBlockContainer className="third-block">
                <ImageBigContainer onClick={() => openModal("gallery-image/image-65.jpg")}>
                  <GalleryImage src={"gallery-image/image-65.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-66.jpg")}>
                  <GalleryImage src={"gallery-image/image-66.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>
            </GalleryImageBlock>

            <GalleryImageBlock>
              <GalleryBlockContainer>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-67.jpg")}>
                  <GalleryImage src={"gallery-image/image-67.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-68.jpg")}>
                  <GalleryImage src={"gallery-image/image-68.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>

              <GalleryBlockContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-69.jpg")}>
                  <GalleryImage src={"gallery-image/image-69.jpg"} />
                </ImageSmallContiner>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-70.jpg")}>
                  <GalleryImage src={"gallery-image/image-70.jpg"} />
                </ImageBigContainer>
              </GalleryBlockContainer>

              <GalleryBlockContainer className="third-block">
                <ImageBigContainer onClick={() => openModal("gallery-image/image-71.jpg")}>
                  <GalleryImage src={"gallery-image/image-71.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-72.jpg")}>
                  <GalleryImage src={"gallery-image/image-72.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>
            </GalleryImageBlock>

            <GalleryImageBlock>
              <GalleryBlockContainer>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-73.jpg")}>
                  <GalleryImage src={"gallery-image/image-73.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-74.jpg")}>
                  <GalleryImage src={"gallery-image/image-74.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>

              <GalleryBlockContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-75.jpg")}>
                  <GalleryImage src={"gallery-image/image-75.jpg"} />
                </ImageSmallContiner>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-76.jpg")}>
                  <GalleryImage src={"gallery-image/image-76.jpg"} />
                </ImageBigContainer>
              </GalleryBlockContainer>

              <GalleryBlockContainer className="third-block">
                <ImageBigContainer onClick={() => openModal("gallery-image/image-77.jpg")}>
                  <GalleryImage src={"gallery-image/image-77.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-78.jpg")}>
                  <GalleryImage src={"gallery-image/image-78.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>
            </GalleryImageBlock>

            <GalleryImageBlock>
              <GalleryBlockContainer>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-79.jpg")}>
                  <GalleryImage src={"gallery-image/image-79.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-80.jpg")}>
                  <GalleryImage src={"gallery-image/image-80.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>

              <GalleryBlockContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-81.jpg")}>
                  <GalleryImage src={"gallery-image/image-81.jpg"} />
                </ImageSmallContiner>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-82.jpg")}>
                  <GalleryImage src={"gallery-image/image-82.jpg"} />
                </ImageBigContainer>
              </GalleryBlockContainer>

              <GalleryBlockContainer className="third-block">
                <ImageBigContainer onClick={() => openModal("gallery-image/image-83.jpg")}>
                  <GalleryImage src={"gallery-image/image-83.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-84.jpg")}>
                  <GalleryImage src={"gallery-image/image-84.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>
            </GalleryImageBlock>

            <GalleryImageBlock>
              <GalleryBlockContainer>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-85.jpg")}>
                  <GalleryImage src={"gallery-image/image-85.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-86.jpg")}>
                  <GalleryImage src={"gallery-image/image-86.jpg"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>

              <GalleryBlockContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image/image-87.jpg")}>
                  <GalleryImage src={"gallery-image/image-87.jpg"} />
                </ImageSmallContiner>
                <ImageBigContainer onClick={() => openModal("gallery-image/image-88.jpg")}>
                  <GalleryImage src={"gallery-image/image-88.jpg"} />
                </ImageBigContainer>
              </GalleryBlockContainer>

              <GalleryBlockContainer className="third-block">
                <ImageBigContainer onClick={() => openModal("gallery-image/image-89.jpg")}>
                  <GalleryImage src={"gallery-image/image-89.jpg"} />
                </ImageBigContainer>
                <ImageSmallContiner onClick={() => openModal("gallery-image-mock/plant-gallery-mock2.png")}>
                  <GalleryImage src={"gallery-image-mock/plant-gallery-mock2.png"} />
                </ImageSmallContiner>
              </GalleryBlockContainer>
            </GalleryImageBlock>


          </GalleryImageContainer>
        </GalleryContent>
      </GalleryContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        ariaHideApp={false}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            maxWidth: "90%",
            maxHeight: "80%",
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <div>
          <CloseButton onClick={closeModal}><RxCross2 size={30} /></CloseButton>
          <ImageContainerModal >
            <img src={modalImageSrc} alt="Enlarged Example" style={{ width: "100%", height: "100%" }} />
          </ImageContainerModal>
        </div>
      </Modal>
    </GalleryStyledWrapper>
    
  );
};
