import styled, { css, keyframes } from "styled-components";

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

interface MobileMenuDropdownProps {
  animationType: "slideIn" | "slideOut" | null;
}

export const ContentMenuMobileStyledWrapper = styled.div``;

export const MobileMenuContainer = styled.div`
  position: relative;
`;

export const MobileMenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
`;

export const MobileMenuDropdown = styled.div<MobileMenuDropdownProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: ${({ animationType }) =>
    animationType === "slideIn" ? css`${slideIn} 0.3s forwards` :
    animationType === "slideOut" ? css`${slideOut} 0.3s forwards` :
    'none'};
`;

export const MobileMenuCloseButton = styled.button`
  position: absolute;
  top: 23px;
  left: 10px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`;

export const MobileMenuItem = styled.a`
  padding: 5px 0;
  text-decoration: none;
  font-size: 18px;
  text-align: center;
  width: 100%;
  color: black;

  &:first-child {
    color: #00c000;
  }
`;

export const FirstMenuItem = styled(MobileMenuItem)`
  color: #00c000;
`;

export const SubMenuItem = styled(MobileMenuItem)`
  color: black;
`;

export const EmptyDropDawnMenu = styled.div`
  height: 100%;
  width: 100%;
  display: block;
  background-color: #ff000000;
`;

export const MainSectionMenuDropDawn = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 30px 0 36px 0;
`;

export const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: black;
  font-size: 18px;
  outline: none;
  position: absolute;
  top: 23px;
  left: 10px;
`;