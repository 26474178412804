import React, { ReactElement, useEffect, useState } from "react";

import {
  ContentMenuStyledWrapper,
  ContentMenuItem,
  SubMenu,
  ContentContainer,
} from "./ContentMenu.styled";
import { observer } from "mobx-react-lite";
import { useUserStore } from "../../../hooks/use-user-store-hook/useUserStore";
import { gardenApi } from "../../../api/requests/garden.api";
import { Button } from "@mui/material";
import { Loader } from "../../shared-components/Loader/Loader";

interface ContentMenuPageProps { }

export const ContentMenuPage = observer(
  (props: ContentMenuPageProps): ReactElement => {
    const { } = props;
    const [openMenuId, setOpenMenuId] = useState<number | null>(null);
    const [plants, setPlants] = useState<any[]>([]);
    const { user } = useUserStore();
    const [loading, setLoading] = useState(true); // Добавляем состояние загрузки

    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await gardenApi.getGarden();
          setPlants(data);
        } catch (error) {
          console.error("Error fetching garden data:", error);
        } finally {
          setLoading(false); // Снимаем состояние загрузки после завершения запроса
        }
      };

      fetchData();
    }, []);

    const handleMouseEnter = (id: number) => {
      setOpenMenuId(id);
    };

    const handleMouseLeave = () => {
      setOpenMenuId(null);
    };

    const menuItems = [
      {
        id: 1,
        name: "A&O Plants Ltd",
        href: "/",
      },
      {
        id: 2,
        name: "Plants",
        subItems: plants,
      },
      {
        id: 3,
        name: "About",
        href: "/about",
      },
      {
        id: 4,
        name: "Gallery",
        href: "/gallery",
      },
      {
        id: 5,
        name: "Contacts",
        href: "/contacts",
      },
    ];

    return (
      <ContentMenuStyledWrapper>
        <ContentContainer>
          {menuItems.map((item) => (
            <ContentMenuItem
              key={item.id}
              onMouseEnter={() => handleMouseEnter(item.id)}
              onMouseLeave={handleMouseLeave}
            >
              <a href={item.href}>{item.name}</a>
              {item.subItems && item.subItems.length > 0 && (
                <SubMenu
                  style={{ display: openMenuId === item.id ? "block" : "none" }}
                >
                  {item.subItems.map((subItem) => (
                    <a key={subItem.id} href={`/gardens/${subItem.id}`}>
                      {subItem.name}
                    </a>
                  ))}
                </SubMenu>
              )}
            </ContentMenuItem>
          ))}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              padding: "0 10px 0 0",
              alignItems: "center",
            }}
          >
            {loading ? (
              <Loader />
            ) : user?.id ? (
              <p>
                <Button variant="outlined" color="primary" href="/personal-account">
                  {user?.name}
                </Button>
              </p>
            ) : (
              <>
                <Button variant="outlined" color="primary" href="/signin">
                  Sign In
                </Button>

                <Button variant="outlined" color="primary" href="/signup">
                  Sign Up
                </Button>
              </>
            )}
          </div>
        </ContentContainer>
      </ContentMenuStyledWrapper>
    );
  }
);
