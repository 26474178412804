import styled from "styled-components";

const statusColorMap: any = {
    new: "#FFD700",
    inProgress: "#FFA500",
    done: "#32CD32",
    cancel: "#FF0000",
};

export const PersonalOrdersStyledWrapper = styled.div``;

export const OrderRowStyledWrapper = styled.div`
    align-items: center;
    padding: 10px;

    .order-base-info {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        border-bottom: 1px solid #ccc;
        padding: 10px 0;
    }

    .orderNumber {
        font-size: 16px;
        font-weight: 600;
    }

    .orderName {
        font-size: 16px;
    }

    .orderPhone {
        font-size: 16px;
    }

    .total-order-price{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
`;

export const ProductRowStyledWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 4px;

    .image {
        width: 50px;
        height: 50px;
        object-fit: cover;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 10px;
        border-bottom: 1px solid #ccc;

        .image {
            display: none;
        }
    }
`;

export const OrderStatusStyledWrapper = styled.div<{ $status: string }>`
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: ${({ $status }) => statusColorMap[$status]};
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    margin-left: auto;
`;

export const OrderExpandStyledWrapper = styled.div`
    svg {
        cursor: pointer;
    }
`;
