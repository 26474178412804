import React, { ReactElement, useEffect, useState } from "react";

import { AddItemButtonContainer, AddItemButtonStyledWrapper, ButtonContainer, ButtonLink, InfoItemContainer, Text, MobileText, PriceInfo, PriceInfoContainer, TextLinkContainer, VerticalLine, WeightInfo, WeightInfoContainer } from "./AddItemButton.styled";
import { GardenItemInterface } from "../../../types/interfaces/GardenItem.interface";

import { FiPlus } from "react-icons/fi";
import { FaCheck } from "react-icons/fa";

import { observer } from "mobx-react-lite";
import { useBascetStore } from "../../../hooks/use-bascet-store-hook/useBacetStore";

interface AddItemButtonProps {
  item: GardenItemInterface;
  selectedPot: number;
  quantity: number;
}

export const AddItemButton = observer((props: AddItemButtonProps): ReactElement => {
  const { item, selectedPot, quantity } = props;
  const { bascet, addItemToBascet, updateItemFromBascet } = useBascetStore();

  const [isAdded, setIsAdded] = useState(false);
  const [isAlreadyAdded, setisAlreadyAdded] = useState(false);

  const isDisabled = quantity === 0;
  const isEmpty = item.pots[selectedPot].quantity === 0 || !item.pots[selectedPot].isAvailable;

  const handleAddToBascet = () => {
    const itemExists = bascet.find(bascetItem => bascetItem.item.pots[bascetItem.selectedPot]._id === item.pots[selectedPot]._id);

    if (itemExists) {
      itemExists.item = item;
      itemExists.selectedPot = selectedPot;
      itemExists.quantity = quantity;
      updateItemFromBascet(itemExists);
      setisAlreadyAdded(true);

    } else {
      addItemToBascet({ item, selectedPot, quantity });
    }
    setIsAdded(true)
  };

  useEffect(() => {
    if (isAdded) {
      const timer = setTimeout(() => {
        setIsAdded(false);
        setisAlreadyAdded(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isAdded]);

  return (
    <AddItemButtonStyledWrapper>
      {isEmpty ? "Plant in this pot is out of stock" :
        <AddItemButtonContainer>

          <InfoItemContainer>
            <PriceInfoContainer>
              <PriceInfo>Price </PriceInfo> <strong>{item.pots[selectedPot].price * quantity}£</strong>
            </PriceInfoContainer>

            <VerticalLine />

            <WeightInfoContainer>
              <WeightInfo>Weight </WeightInfo> <strong>{(item.pots[selectedPot].weight * quantity).toFixed(1)}kg</strong>
            </WeightInfoContainer>
          </InfoItemContainer>

          <ButtonContainer>
            <ButtonLink isDisabled={isDisabled} onClick={handleAddToBascet} disabled={isDisabled}>
              <TextLinkContainer>

                <Text>
                  {isAdded ? (
                    isAlreadyAdded ? (
                      "Item information updated"
                    ) : (
                      "Item added"
                    )
                  ) : (
                    "Add"
                  )}
                </Text>


                {isAdded ? (
                  <MobileText>
                    <FaCheck />
                  </MobileText>

                ) : (

                  <MobileText>
                    <FiPlus />
                  </MobileText>
                )}


              </TextLinkContainer>
            </ButtonLink>
          </ButtonContainer>

        </AddItemButtonContainer>

      }
      <MobileText style={{ paddingTop: "2px" }}>
        {isAdded ? (
          isAlreadyAdded ? (
            "Item information updated"
          ) : (
            "Item added"
          )
        ) : (
          null
        )}
      </MobileText>
    </AddItemButtonStyledWrapper >
  );
});
