import React, { ReactElement, useEffect } from "react";

import { PrivacyContainer, PrivacyInfo, PrivacyStyledWrapper, PrivacyTitle } from "./Privacy.styled";

interface PrivacyPageProps { }

export const PrivacyPage = (props: PrivacyPageProps): ReactElement => {
  const { } = props;

  useEffect(() => {
    document.title = `Privacy : A&O Plants`;
  }, []);

  return (
    <PrivacyStyledWrapper>
      <PrivacyContainer>
        <PrivacyInfo>
          <PrivacyTitle>Privacy Policy</PrivacyTitle>
          <p>Welcome to our Privacy Policy. We know this may sound formal, but it's necessary to ensure your online shopping experience is secure and straightforward.</p>
          <ol>
            <li><strong>Introduction</strong> 1.1 This website is operated by A&O PLANTS LIMITED, registered in England (company number 15476868), with our office at Chocolate Factory 2, 4 Coburg Road, London, England, N22 6UJ. 1.2 Our privacy policy applies only to the information we collect from you. If you navigate to a third-party website via a link on our site, their privacy practices may differ.</li>
            <li><strong>Why We Collect Your Information</strong> 2.1 We process the information you provide for these purposes:
              <ul style={{ marginLeft: "20px" }}>
                <li>To manage your account with us.</li>
                <li>To respond to your requests for advice or information.</li>
                <li>To keep you informed about our newsletters and services, with your consent.</li>
                <li>To tailor the website to your preferences.</li>
                <li>To fulfill our legal and regulatory obligations.</li>
                <li>To process any orders you place.</li>
              </ul>
            </li>
            <li><strong>Information We Collect</strong> 3.1 The types of information we may collect include:
              <ul style={{ marginLeft: "20px" }}>
                <li>Your name and address.</li>
                <li>The company you represent.</li>
                <li>Your phone number and email address.</li>
                <li>VAT number details.</li>
              </ul>
            </li>
            <li><strong>Sensitive Information</strong> 4.1 We will never collect sensitive information about you without your explicit consent.</li>
            <li><strong>Legal Basis for Processing</strong> 5.1 The main reason we process your personal data is to fulfill a contract with you for the sale of goods. We may also process your data to pursue legitimate interests or comply with legal obligations.</li>
            <li><strong>How We Store Your Data</strong> 6.1 Your data is securely stored in our electronic database and may also be printed and stored in our data storage system.</li>
            <li><strong>Sharing Your Data</strong> 7.1 We may need to share your data with:
              <ul style={{ marginLeft: "20px" }}>
                <li>Our delivery partners.</li>
                <li>Our payment processing company. 7.2 We ensure that these third parties handle your data securely and in compliance with the law.</li>
              </ul>
            </li>
            <li><strong>Disclosure of Your Data</strong> 8.1 We may disclose your personal data:
              <ul style={{ marginLeft: "20px" }}>
                <li>When required by law, such as in cases of fraud.</li>
                <li>To comply with instructions from regulatory authorities.</li>
                <li>To demonstrate compliance with professional standards or statutory requirements.</li>
              </ul>
            </li>
            <li><strong>Your Rights</strong> 9.1 You have the right to:
              <ul style={{ marginLeft: "20px" }}>
                <li>Request access to your data.</li>
                <li>Request corrections or deletion of your data.</li>
                <li>Request restrictions on or object to how we process your data.</li>
                <li>Request your data in a machine-readable format or have it transferred to another organization. 9.2 To exercise these rights, please contact us at the address provided on our website.</li>
              </ul>
            </li>
            <li><strong>Data Security</strong> 10.1 We adhere to strict security procedures to protect your personal information, in line with the General Data Protection Regulation (GDPR) and the Data Protection Act 2018.
            </li>
            <li><strong>Marketing Communications</strong> 11.1 We may contact you for marketing purposes via post, email, or phone. If you'd like to receive updates about our services and offers, you can opt in through your account on the website. You can withdraw your consent at any time by emailing us at <a href="mailto:officeaiop@gmail.com" style={{ textDecoration: "underline" }}>officeaiop@gmail.com</a>.</li>
            <li><strong>Complaints</strong> 12.1 If you believe we have breached our Privacy Policy, please contact us at <a href="mailto:officeaiop@gmail.com" style={{ textDecoration: "underline" }}>officeaiop@gmail.com</a>. We will investigate your complaint and respond within 40 days.
            </li>
            <li><strong>External Complaints</strong> 3.1 You have the right to complain to the Information Commissioner’s Office at <a href="https://www.ico.org.uk" style={{ textDecoration: "underline" }}>www.ico.org.uk</a> if you are unhappy with how we manage your data.
            </li>
            <li><strong>Cookies</strong> 14.1 Cookies are small text files placed on your computer by websites you visit. Our website uses cookies to function and to improve your experience. The cookies we use include:
              <ul style={{ marginLeft: "20px" }}>
                <li><strong>session_id:</strong> Essential for browsing and making purchases, expires after 1 week unless you revisit our site.</li>
                <li><strong>Google Analytics cookies (_ga, _gat, _gid):</strong> Collect anonymous information about how visitors use our site. To learn more, visit Google’s <a href="http://www.google.com/analytics/learn/privacy.html" style={{ textDecoration: "underline" }}>privacy policy</a>.
                </li>
                <li><strong>Error tracking cookies (ahoy_visitor, traffic_source):</strong> Help us identify and fix any issues on our site.</li>
              </ul>
            </li>
            <li><strong>Retention of Your Information</strong> 15.1 We will retain your information as long as necessary to fulfill the purposes outlined in this policy or as required by law. If you no longer wish us to use your data, you can close your account by contacting us, and we will delete your information after 12 months unless we are legally required to retain it for longer.
            </li>
          </ol>
          <p>We appreciate your understanding of our policies and are committed to protecting your privacy while providing you with the best possible service.</p>
        </PrivacyInfo>
      </PrivacyContainer>
    </PrivacyStyledWrapper>
  );
};
