import React, { ReactElement } from "react";

import { ArrowContainer, ArrowDownIcon, ArrowUpIcon, InputNumberContainer, InputNumberMobileContainer, InputNumberStyledWrapper, QuantityMobileContainer, ValueDisplay } from "./InputNumber.styled";
import { FiPlus } from "react-icons/fi";
import { FiMinus } from "react-icons/fi";

interface InputNumberProps {
  value: number;
  maxValue: number;
  isAvailable: boolean;
  onChange: (newValue: number) => void;
}

export const InputNumber = (props: InputNumberProps): ReactElement => {
  const { value, maxValue, onChange, isAvailable } = props;
  let currentValue = value;

  if (maxValue < value) {
    currentValue = maxValue;
  }

  const handleIncrement = () => {
    if (isAvailable && currentValue < maxValue) {
      if ((currentValue + 5) > maxValue) {
        currentValue = maxValue;
        onChange(currentValue);
      } else {
        onChange(currentValue + 5);
      }
    }
  };

  const handleDecrement = () => {
    if (isAvailable && currentValue > 0) {
      const nextValue = Math.floor((currentValue - 1) / 5) * 5;
      const finalValue = Math.max(nextValue, 0);
      onChange(finalValue);
    }
  };

  return (
    <InputNumberStyledWrapper>
      <QuantityMobileContainer>
        Quantity
      </QuantityMobileContainer>
      <InputNumberContainer>
        <ValueDisplay>{currentValue}</ValueDisplay>
        <ArrowContainer>
          <ArrowUpIcon onClick={handleIncrement} isDisabled={!isAvailable} />
          <ArrowDownIcon onClick={handleDecrement} isDisabled={!isAvailable} />
        </ArrowContainer>
      </InputNumberContainer>
      <InputNumberMobileContainer>
        <FiMinus onClick={handleDecrement} style={{ cursor: isAvailable ? "pointer" : "not-allowed", color: "black" }} />
        <ValueDisplay>{currentValue}</ValueDisplay>
        <FiPlus onClick={handleIncrement} style={{ cursor: isAvailable ? "pointer" : "not-allowed", color: "black" }} />
      </InputNumberMobileContainer>
    </InputNumberStyledWrapper>
  );
};