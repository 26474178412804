import { httpClient } from "../httpClient/HttpClient";

export class GardenApi {
  private GARDEN_ENDPOINT = "garden";

  getGarden = async (): Promise<any> => {
    const data = await httpClient.get(`${this.GARDEN_ENDPOINT}/`);

    return data;
  };

  getGardenById = async (gardenId:string): Promise<any> => {
    const data = await httpClient.get(`${this.GARDEN_ENDPOINT}/${gardenId}`);

    return data;
  };
}

export const gardenApi = new GardenApi();
