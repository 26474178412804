import React, { ReactElement } from "react";

import { LoaderWrapper, Spinner } from "./Loader.styled";

interface LoaderProps {
  style?: React.CSSProperties;

}

export const Loader = (props: LoaderProps): ReactElement => {
  const {style} = props;

  return (
    <LoaderWrapper style={style}>
      <Spinner />
    </LoaderWrapper>
  );
};
