import styled from "styled-components";

export const SigninPageStyledWrapper = styled.div`
    width: 100%;
    display: flex;
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
