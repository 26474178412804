import styled from "styled-components";
import { contentWidth, mobile } from "../../styles/variables.styled";

export const ContactsStyledWrapper = styled.div`
width:100%;
display: flex;
justify-content: center;
`;

export const ContactsContainer = styled.div`
width: ${contentWidth};
padding: 0 0 10px 0;

@media screen and (max-width: ${mobile}) {
    padding: 10px 0;
}
`;

export const ContactsInfoContainer = styled.div`
background-color: #fff;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;


export const ContactsTitle = styled.div`
font-weight: bold;
font-size: 24px;
padding: 50px 0 27px 0;

`;

export const ContactsInfoNumber = styled.div`
font-size: 18px;
padding: 20px 0 10px 0;
display: flex;
justify-content: center;
align-items: center;

`;

export const ContactsInfoEmail = styled.div`
font-size: 18px;
padding: 10px 0 10px 0;
display: flex;
justify-content: center;
align-items: center;
`;

export const ContactsInfoLocation = styled.div`
font-size: 18px;
padding: 10px 0 60px 0;
display: flex;
justify-content: center;
align-items: center;
`;
