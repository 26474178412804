import styled from "styled-components";
import { contentWidth, mobile } from "../../styles/variables.styled";

export const BasketStyledWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const BasketContainer = styled.div`
    width: 100%;
    max-width: ${contentWidth};
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;

    @media screen and (max-width: ${mobile}) {
      font-size: 14px;
  
  }

    
`;

export const BasketHelpContainer = styled.div`
    background-color: white;
    padding: 30px 40px;


`;

export const TitleBasket = styled.h1`
    display: flex;
    justify-content: center;
    align-items: center;


`;

export const BasketText = styled.div`
    margin-top: 30px;
    @media screen and (max-width: ${mobile}) {
      display: flex;
    justify-content: center;
    align-items: center;
  text-align: center;
  }
`;

export const BasketHelp = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;

    @media screen and (max-width: ${mobile}) {
      flex-direction  : column;
      
  }

`;

export const BasketInput = styled.input`
    border: none;
    border-radius: 4px;
    width: 200px;
    height: 40px;
    margin-right: 20px;
    background-color: #CCF2CC;
    padding: 0 10px;

    &:focus{
        outline: none;

    }

    @media screen and (max-width: ${mobile}) {
      margin-top: 10px;
      margin-right: 0;
      padding: 0 10px;

  }
`;

export const BasketButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00C000;
    color: white;
    border: none;
     border-radius: 2px;
     width: 200px;
     height: 40px;
     cursor: pointer;
     margin-right: 20px;

  &:hover {
    background-color: #009900;
  }

  @media screen and (max-width: ${mobile}) {
      margin-top: 10px;
      margin-right: 0;

  }
`;

export const BasketTableContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: row;
    align-items: center;
    margin: 20px 0;
    border-radius: 4px;

`;

export const BasketHeaderCell = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14%;

`;

export const BasketTableHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: white;
    padding: 20px 0;

    @media screen and (max-width: ${mobile}) {
      display: none;
    }
`;

export const BasketTableHeaderMobileContainer = styled.div`
    display: none;

    @media screen and (max-width: ${mobile}) {
      display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: white;
    padding: 20px 0;  
  }

`;

export const BasketTableBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    border-radius: 4px;

    @media screen and (max-width: ${mobile}) {
      display: none;
  
  }
`;

export const BasketTableBodyMobileContainer = styled.div`
    display: none;

    @media screen and (max-width: ${mobile}) {
      display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    border-radius: 4px;  
  }
`;

export const BasketBodyCell = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 5px 0;
    padding: 10px 0;
    background-color: white;

    @media screen and (max-width: ${mobile}) {
      margin: 0 0 5px 0;

  }

`;

export const BasketBodySpan = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14%;


`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;

  svg {
    position: absolute;
    top: 70%;
    left: 20%;
    transform: translate(-50%, -50%);
    color: rgba(0, 192, 0, 0.5);
    font-size: 24px;
    pointer-events: none; 
  }

  @media screen and (max-width: ${mobile}) {
      font-size: 16px;
      height: 60px;
      width: 60px;
  }
`;


export const ItemImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
`;



export const QuantityChangeBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  background-color: #CCF2CC;
  border-radius:50%;
  margin: 0 10px;
  cursor: pointer;

  @media screen and (max-width: ${mobile}) {
    height: 18px;
    width: 18px;
    margin: 0 1px;
  }
`;

export const BasketOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: right;
  background-color: white;
  padding: 23px;
  margin: 0 0 10px 0;

  @media screen and (max-width: ${mobile}) {
    padding: 10px 0;
  
  }
`;

export const OrderInfo = styled.div`
  display: flex;
  justify-content: right;
  align-items: right;
  background-color: white;
  margin: 7px 40px;

  @media screen and (max-width: ${mobile}) {
    margin: 7px 30px;
  
  }
`;

export const DetailedText = styled.div`
font-weight: bold;
min-width: 140px;
display:flex;
justify-content: right;


@media screen and (max-width: ${mobile}) {
  min-width: 90px;
  
  }

`;

export const OrderButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00C000;
    color: white;
    border: none;
     border-radius: 2px;
     width: 250px;
     height: 34px;
     cursor: pointer;

  &:hover {
    background-color: #009900;
  }

  @media screen and (max-width: ${mobile}) {
    width: 170px;
  
  }
`;

export const BasketBodyMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
    width: 100%;
    margin: 5px 0;
    padding: 10px 0;
    background-color: white;

`;

export const ImageContainerModal = styled.div`
  height: 600px;
  width: auto;

  @media screen and (max-width: ${mobile}) {
    width: 280px;
    height: 100%;
  }
`;


export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #e0e0e0;
  }
`;