import styled from "styled-components";

export const SigninFormStyledWrapper = styled.div``;

export const Title = styled.div`
font-size: 24px;
font-weight: bold;
text-align: center;
margin-bottom: 10px;
`;

export const Text = styled.div`
font-size: 16px;
text-align: center;
margin-top: 30px;
`;
