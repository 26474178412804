import { httpClient } from "../httpClient/HttpClient";

export class UserApi {
  private USER_ENDPOINT = "user";

  public getUser = async (): Promise<any> => {
    const user = await httpClient.get(`${this.USER_ENDPOINT}/me`);

    return user;
  };

  public updateUser = async (user: any): Promise<any> => {
    const updatedUser = await httpClient.put(`${this.USER_ENDPOINT}/update`, user);

    return updatedUser;
  };
}

export const userApi = new UserApi();
