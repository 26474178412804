import { BascetInterface } from "../../types/interfaces/Bascet.interface";
import { UserInterface } from "../../types/interfaces/User.interface";
import { httpClient } from "../httpClient/HttpClient";

const orderData = [
  {
    id: "64a7fe92",
    orderNumber: 1,
    client: {
      _id: "64a7fe92d1b5fa38c3c454ec",
      phone: "+123456789",
      name: "John Doe",
      isAccepted: true,
      email: "john@example.com",
      address: "123 Main St",
      postCode: "12345",
      personalDiscount: 10,
      numberVAT: "123456789",
    },
    products: [
      {
        product: {
          _id: "64a7fe92d1b5fa38c3c454ea",
          data: { name: "Flower", color: "Red" },
          pots: [
            {
              volume: 1,
              quantity: 100,
              price: 10,
              weight: 0.5,
              isAvailable: true,
            },
            {
              volume: 2,
              quantity: 50,
              price: 20,
              weight: 1,
              isAvailable: true,
            },
          ],
          image: "",
          garden: "64a7fe92d1b5fa38c3c454e1",
        },
        selectedPot: 1,
        quantity: 10,
      },
      {
        product: {
          _id: "64a7fe92d1b5fa38c3c454eb",
          data: { name: "Tree", height: "5m" },
          pots: [
            {
              volume: 3,
              quantity: 20,
              price: 50,
              weight: 5,
              isAvailable: true,
            },
            {
              volume: 4,
              quantity: 10,
              price: 100,
              weight: 10,
              isAvailable: false,
            },
          ],
          image: "",
          garden: "64a7fe92d1b5fa38c3c454e3",
        },
        selectedPot: 2,
        quantity: 5,
      },
    ],
    status: "new",
    isDiscountWithin7Days: false,
  },
  {
    id: "64a7fe93",
    orderNumber: 2,
    client: {
      _id: "64a7fe92d1b5fa38c3c454ed",
      phone: "+987654321",
      name: "Jane Smith",
      isAccepted: true,
      email: "jane@example.com",
      address: "456 Elm St",
      postCode: "54321",
      personalDiscount: 15,
      numberVAT: "987654321",
    },
    products: [
      {
        product: {
          _id: "64a7fe92d1b5fa38c3c454ea",
          data: { name: "Flower", color: "Red" },
          pots: [
            {
              volume: 1,
              quantity: 100,
              price: 10,
              weight: 0.5,
              isAvailable: true,
            },
            {
              volume: 2,
              quantity: 50,
              price: 20,
              weight: 1,
              isAvailable: true,
            },
          ],
          image: "",
          garden: "64a7fe92d1b5fa38c3c454e1",
        },
        selectedPot: 1,
        quantity: 8,
      },
      {
        product: {
          _id: "64a7fe92d1b5fa38c3c454eb",
          data: { name: "Tree", height: "5m" },
          pots: [
            {
              volume: 3,
              quantity: 20,
              price: 50,
              weight: 5,
              isAvailable: true,
            },
            {
              volume: 4,
              quantity: 10,
              price: 100,
              weight: 10,
              isAvailable: false,
            },
          ],
          image: "",
          garden: "64a7fe92d1b5fa38c3c454e3",
        },
        selectedPot: 2,
        quantity: 7,
      },
    ],
    status: "inProgress",
    isDiscountWithin7Days: true,
  },
  {
    id: "64a7fe94",
    orderNumber: 3,
    client: {
      _id: "64a7fe92d1b5fa38c3c454ec",
      phone: "+123456789",
      name: "John Doe",
      isAccepted: true,
      email: "john@example.com",
      address: "123 Main St",
      postCode: "12345",
      personalDiscount: 10,
      numberVAT: "123456789",
    },
    products: [
      {
        product: {
          _id: "64a7fe92d1b5fa38c3c454ea",
          data: { name: "Flower", color: "Red" },
          pots: [
            {
              volume: 1,
              quantity: 100,
              price: 10,
              weight: 0.5,
              isAvailable: true,
            },
            {
              volume: 2,
              quantity: 50,
              price: 20,
              weight: 1,
              isAvailable: true,
            },
          ],
          image: "",
          garden: "64a7fe92d1b5fa38c3c454e1",
        },
        selectedPot: 3,
        quantity: 12,
      },
      {
        product: {
          _id: "64a7fe92d1b5fa38c3c454eb",
          data: { name: "Tree", height: "5m" },
          pots: [
            {
              volume: 3,
              quantity: 20,
              price: 50,
              weight: 5,
              isAvailable: true,
            },
            {
              volume: 4,
              quantity: 10,
              price: 100,
              weight: 10,
              isAvailable: false,
            },
          ],
          image: "",
          garden: "64a7fe92d1b5fa38c3c454e3",
        },
        selectedPot: 4,
        quantity: 6,
      },
    ],
    status: "done",
    isDiscountWithin7Days: false,
  },
];

export class OrderApi {
  private ORDER_ENDPOINT = "order";

  getOrder = async (): Promise<any> => {
    const data = await httpClient.get(`${this.ORDER_ENDPOINT}/`);

    return data;
  };

  getOrderByUserId = async (id: string): Promise<any> => {
    const data = await httpClient.get(`${this.ORDER_ENDPOINT}/${id}`);
    return data;
  };

  createOrder = async (
    basket: BascetInterface[],
    user: UserInterface,
    isDiscountWithin7Days: boolean
  ) => {
    const data = await httpClient.post(`${this.ORDER_ENDPOINT}/new`, {
      basket,
      user,
      isDiscountWithin7Days,
    });
    return data;
  };
}

export const orderApi = new OrderApi();
