import { Link } from "react-router-dom";
import styled from "styled-components";

export const LogoStyledWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
cursor: pointer;

`;

export const LogoImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoImage = styled.img`
    width: auto;
    @media screen and (max-width: 768px) {
    height: 51px;
  }
`;

export const LogoInfo = styled.p`
  font-size: 20px;
  color: #00C000;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    font-size: 18px;

  }
`;

const BascetLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;