import React, { ReactElement, useEffect, useState } from "react";

import { AddressLogoContainer, FooterColumn, FooterContainer, FooterLink, FooterLogoMobileColumn, FooterMap, FooterMapMobileColumn, FooterMobileStyledWrapper, FooterStyledWrapper, IconsMobileContainer, MenuFooterItemContainer } from "./Footer.styled";
import { Logo } from "../Logo/Logo";

import { FaInstagram } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { gardenApi } from "../../../api/requests/garden.api";
import { useUserStore } from "../../../hooks/use-user-store-hook/useUserStore";
import { Loader } from "../Loader/Loader";

interface FooterProps { }

export const Footer = (props: FooterProps): ReactElement => {
  const [plants, setPlants] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const { user } = useUserStore();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await gardenApi.getGarden();
        setPlants(data);
      } catch (error) {
        console.error("Error fetching garden data:", error);
      } finally {
        setLoading(false);
        setLoadingUser(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <FooterStyledWrapper>
        <FooterContainer>
          <FooterColumn>
            <Logo />
            <address style={{lineHeight:"1.5em"}}>
            Exeter Devon<br />
              {/* 4 Coburg Road,<br />
              London, England, N22 6UJ<br /> */}
              +44 7832 695407
            </address>
            <div>
              <a href="" target="_blank" rel="noopener noreferrer" >
                <FaInstagram size={26} />
              </a>
              <a href="" target="_blank" rel="noopener noreferrer" >
                <FaFacebookSquare size={26} />
              </a>
              <a href="" target="_blank" rel="noopener noreferrer" >
                <FaLinkedin size={26} />
              </a>
            </div>
          </FooterColumn>
          <FooterColumn>
            <h3>Menu</h3>
            <FooterLink href="/about">About</FooterLink>
            <FooterLink href="/gallery">Gallery</FooterLink>
            <FooterLink href="/contacts">Contacts</FooterLink>
            {loadingUser ? (
              <Loader />
            ) : user?.id ? (
              null
            ) : (
              <>
                <FooterLink href="/signin">
                  Sign In
                </FooterLink>

                <FooterLink href="/signup">
                  Sign Up
                </FooterLink>
              </>
            )}
          </FooterColumn>
          <FooterColumn>
            <h3>Plants</h3>
            {loading ? (
              <Loader />
            ) : (plants.map(plant => {
              return <FooterLink key={plant.id} href={`/gardens/${plant.id}`}>{plant.name}</FooterLink>
            }))}
          </FooterColumn>
          <FooterColumn>
            <h3>Policies</h3>
            <FooterLink href="/cancellation" style={{ textDecoration: "underline" }}>Cancellation Policy</FooterLink>
            <FooterLink href="/privacy" style={{ textDecoration: "underline" }}>Privacy Policy</FooterLink>
            <FooterLink href="/terms-and-conditions" style={{ textDecoration: "underline" }}>Terms and Conditions</FooterLink>
          </FooterColumn>
          <FooterColumn>
            <FooterMap>
              <iframe
                title="Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d40412.13464559!2d-3.5547468135790643!3d50.724420941133936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486c52c4d4498da7%3A0xa976e3256bdfad1e!2z0K3QutGB0LXRgtC10YAsINCS0LXQu9C40LrQvtCx0YDQuNGC0LDQvdC40Y8!5e0!3m2!1sru!2sua!4v1725285041130!5m2!1sen!2sus"
                width="460"
                height="230"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen={false}
                aria-hidden="false"
                tabIndex={0}
              />
            </FooterMap>
          </FooterColumn>
        </FooterContainer>
      </FooterStyledWrapper>
      <FooterMobileStyledWrapper>
        <AddressLogoContainer>
          <FooterLogoMobileColumn>
            <div style={{ marginLeft: "6px" }}>
              <Logo />
            </div>
            <address style={{lineHeight:"1.5em"}}>
            Exeter Devon<br />
              {/* 4 Coburg Road,<br />
              London, England, N22 6UJ<br /> */}
              +44 7832 695407
            </address>
            <IconsMobileContainer>
              <a href="" target="_blank" rel="noopener noreferrer" >
                <FaInstagram size={26} />
              </a>
              <a href="" target="_blank" rel="noopener noreferrer" >
                <FaFacebookSquare size={26} />
              </a>
              <a href="" target="_blank" rel="noopener noreferrer" >
                <FaLinkedin size={26} />
              </a>
            </IconsMobileContainer>
          </FooterLogoMobileColumn>
          <FooterMapMobileColumn>
            <FooterMap>
              <iframe
                title="Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d40412.13464559!2d-3.5547468135790643!3d50.724420941133936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486c52c4d4498da7%3A0xa976e3256bdfad1e!2z0K3QutGB0LXRgtC10YAsINCS0LXQu9C40LrQvtCx0YDQuNGC0LDQvdC40Y8!5e0!3m2!1sru!2sua!4v1725285041130!5m2!1sen!2sus"
                style={{ border: 0 }}
                allowFullScreen={true}
                aria-hidden="false"
                tabIndex={0}
              />
            </FooterMap>
          </FooterMapMobileColumn>
        </AddressLogoContainer>
        <MenuFooterItemContainer>
          <FooterColumn>
            <h3>Menu</h3>
            <FooterLink href="/about">About</FooterLink>
            <FooterLink href="/gallery">Gallery</FooterLink>
            <FooterLink href="/contacts">Contacts</FooterLink>
            {loadingUser ? (
              <Loader />
            ) : user?.id ? (
              null
            ) : (
              <>
                <FooterLink href="/signin">
                  Sign In
                </FooterLink>

                <FooterLink href="/signup">
                  Sign Up
                </FooterLink>
              </>
            )}
          </FooterColumn>
          <FooterColumn>
            <h3>Plants</h3>
            {loading ? (
              <Loader />
            ) : (plants.map(plant => {
              return <FooterLink key={plant.id} href={`/gardens/${plant.id}`}>{plant.name}</FooterLink>
            }))}
          </FooterColumn>
          <FooterColumn>
            <h3>Policies</h3>
            <FooterLink href="/cancellation" style={{ textDecoration: "underline" }}>Cancellation Policy</FooterLink>
            <FooterLink href="/privacy" style={{ textDecoration: "underline" }}>Privacy Policy</FooterLink>
            <FooterLink href="/terms-and-conditions" style={{ textDecoration: "underline" }}>Terms and Conditions</FooterLink>
          </FooterColumn>
        </MenuFooterItemContainer>
      </FooterMobileStyledWrapper>
    </div>
  );
};