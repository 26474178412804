import { action, computed, makeAutoObservable } from "mobx";
import type { BascetInterface } from "../types/interfaces/Bascet.interface";
import { makePersistable } from 'mobx-persist-store';

export class BascetStore {
    public bascet: BascetInterface[] = [];

    constructor() {
        makeAutoObservable(this, {
            setBascet: action,
            addItemToBascet: action,
            deleteItemFromBascet: action,
            updateItemFromBascet: action,
            clearBasket: action,
            summaryWeight: computed,
        });

        makePersistable(this, {
            name: 'BascetStore',
            properties: ['bascet'],
            storage: window.localStorage,
        });

        this.setBascet = this.setBascet.bind(this);
        this.addItemToBascet = this.addItemToBascet.bind(this);
        this.deleteItemFromBascet = this.deleteItemFromBascet.bind(this);
        this.updateItemFromBascet = this.updateItemFromBascet.bind(this);
        this.clearBasket = this.clearBasket.bind(this); 
    }

    setBascet(bascet: BascetInterface[]) {
        this.bascet = bascet;
    }

    clearBasket() {
        this.bascet = [];
    }

    addItemToBascet(bascet: BascetInterface) {
        const id = bascet.item.pots[bascet.selectedPot]._id;
        this.bascet.push({ id, ...bascet });
    }

    deleteItemFromBascet(idToFilter: string) {
        this.bascet = this.bascet.filter(({ id }) => id !== idToFilter);
    }

    updateItemFromBascet(bascetToUpdate: BascetInterface) {
        this.bascet = this.bascet.map((bascet) =>
            bascetToUpdate.id === bascet.id ? bascetToUpdate : bascet
        );
    }

    get summaryWeight() {
        return this.bascet.reduce((total, plant) => {
            return total + (plant.item.pots[plant.selectedPot].weight * plant.quantity);
        }, 0);
    }
}