import React, { ReactElement, useEffect } from "react";

import { FaAngleDown } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";

import {
  OrderExpandStyledWrapper,
  OrderRowStyledWrapper,
  OrderStatusStyledWrapper,
  PersonalOrdersStyledWrapper,
  ProductRowStyledWrapper,
} from "./PersonalOrders.styled";
import { orderApi } from "../../../api/requests/order.api";
import { observer } from "mobx-react-lite";
import { useUserStore } from "../../../hooks/use-user-store-hook/useUserStore";

interface PersonalOrdersProps { }

const DEFAULT_IMAGE = 'https://cdn.pixabay.com/photo/2022/11/08/14/42/monstera-7578722_640.png';

export const PersonalOrders = observer(
  (props: PersonalOrdersProps): ReactElement => {
    const { } = props;

    const { user, ordersHistory, setOrdersHistory } = useUserStore();

    const [isLoading, setIsLoading] = React.useState(false);
    const [openOrders, setOpenOrders] = React.useState<any>({});

    const fetchOrders = async () => {
      if (!user?.id) {
        return;
      }

      setIsLoading(true);

      const orders: any = await orderApi.getOrderByUserId(user?.id);

      setOrdersHistory(orders);

      setIsLoading(false);
    };

    useEffect(() => {
      fetchOrders();
    }, []);

    const handleCloseOpenOrder = (orderId: string) => {
      setOpenOrders((prevOpenOrders: any) => ({
        ...prevOpenOrders,
        [orderId]: !prevOpenOrders[orderId],
      }));
    };

    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (!ordersHistory.length) {
      return <div>No orders</div>;
    }

    return (
      <PersonalOrdersStyledWrapper>
        {ordersHistory.map((order: any) => {
          const isOpen = openOrders[order.id];
          const { id, orderNumber, client, products, status } = order;

          const totalOrderPrice = products.reduce((acc: number, item: any) => {
            const { product, productSnapshot, selectedPot, quantity } = item;
            const pot = product?.pots?.[selectedPot] || productSnapshot.pots ||{};
            const { price } = pot || {};
            return acc + (price * quantity);
          }, 0);

          const totalWeight = products.reduce((acc: number, item: any) => {
            const { product, productSnapshot, selectedPot, quantity } = item;
            const pot = product?.pots?.[selectedPot] || productSnapshot.pots || {};
            const { weight } = pot || {};
            return acc + (weight * quantity);
          }, 0);

          const salesFromNumberPalets = () => {
            const palets = Math.floor(totalWeight / 400);
            if (palets < 3) {
              return palets;
            } else if (palets === 3) {
              return 4;
            } else {
              return 6;
            }
          };
          if (!user?.id) {
            return;
          }
          const totalDiscount = order.isDiscountWithin7Days ? 2 : 0 +
            parseInt(user.personalDiscount) +
            salesFromNumberPalets();

          const discountedPrice = totalOrderPrice * (1 - totalDiscount / 100);

          return (
            <OrderRowStyledWrapper key={id}>
              <div className="order-base-info">
                <OrderExpandStyledWrapper>
                  {isOpen ? (
                    <FaAngleUp onClick={() => handleCloseOpenOrder(id)} />
                  ) : (
                    <FaAngleDown
                      onClick={() => handleCloseOpenOrder(id)}
                    />
                  )}
                </OrderExpandStyledWrapper>

                <p className="orderNumber">{orderNumber}</p>
                <p className="orderName">{client.name}</p>
                <p className="orderPhone">{client.phone}</p>

                <OrderStatusStyledWrapper $status={status}>
                  <p>{status}</p>
                </OrderStatusStyledWrapper>
              </div>

              {isOpen && <div className="order-base-items">
                {
                  products.map((item: any) => {
                    const { product, productSnapshot, selectedPot, quantity } = item;
                    const { data = productSnapshot.data, image = DEFAULT_IMAGE, pots } = product || {};
                    const { name } = data || {};

                    const pot = pots?.[selectedPot] || productSnapshot.pots || {};

                    const { volume, price, weight } = pot || {};

                    return (
                      <ProductRowStyledWrapper>
                        <img className="image" src={image.imageUrl || DEFAULT_IMAGE} alt={name} />
                        <p className="name">{name || '-'}</p>
                        <p className="potVolume">volume: {volume || '-'}</p>
                        <p className="potWeight">weight: {weight || '-'}</p>
                        <p className="potPrice">price: {price + "£" || '-'}</p>
                        <p className="quantity">quantity: {quantity || '-'}</p>
                        <p className="quantity">total price: {quantity * price + "£" || '-'}</p>
                      </ProductRowStyledWrapper>
                    );
                  })
                }
                <div className="total-order-price">
                  <strong >Total Price: {discountedPrice}£ (With Discount)</strong>
                  <strong>Total Weight: {totalWeight}kg</strong>
                </div>
              </div>}

            </OrderRowStyledWrapper>
          );
        })}
      </PersonalOrdersStyledWrapper>
    );
  }
);
