import React, { ReactElement } from "react";

import { InputStyled, InputStyledWrapper } from "./Input.styled";

interface InputProps {
  placeholder: string;
  type?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  isValid?: boolean;
  errorMessage?: string[];
}

export const Input = (props: InputProps): ReactElement => {
  const {
    placeholder = "",
    type = "text",
    value = "",
    onChange,
    name = "",
    id = "",
    required = false,
    isValid = true,
    errorMessage = [],
    ...rest
  } = props;

  const isShowErrorMessages = !isValid && errorMessage.length > 0;

  return (
    <InputStyledWrapper>
      <InputStyled
        type={type}
        value={value}
        onChange={onChange}
        name={name}
        id={id}
        required={required}
        $isValid={isValid}
        placeholder={placeholder}
        {...rest}
      />

      {isShowErrorMessages && <div>
        {errorMessage.map((error, index) => (
          <span key={index} style={{
            color: "#f00",
            display: "block",
            fontSize: "12px",
            marginTop: "-5px",
          }}>{error}</span>
        ))}
      </div>}
    </InputStyledWrapper>
  );
};
