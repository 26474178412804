import React from "react";
import { VerificationCodeStyled } from "./VerificationCodeInput.styled";
import ReactInputVerificationCode from "react-input-verification-code";

interface VerificationCodeInputProps {
  value: string;
  autoFocus: boolean;
  isInvalid?: boolean;
  onChange: (code: string) => void;
  onCompleted?: () => void;
}

export const VerificationCodeInput = (props: VerificationCodeInputProps) => {
  const { value, autoFocus = false, isInvalid, onChange, onCompleted } = props;

  const handleCompleted = () => {
    onCompleted?.();
  };

  return (
    <VerificationCodeStyled>
      <ReactInputVerificationCode
        value={value}
        onChange={onChange}
        onCompleted={handleCompleted}
        autoFocus={autoFocus}
      />

      {isInvalid && (
        <p
          style={{
            color: "red",
            textAlign: "center",
            fontSize: 12,
            marginTop: 5,
          }}
        >
          Invalid or expired code
        </p>
      )}
    </VerificationCodeStyled>
  );
};
