import React from "react";
import { BrowserRouter } from "react-router-dom";

import ReactDOM from "react-dom/client";
import App from "./App";

import "./index.css";
import { createGlobalStyle } from "styled-components";
import "./styles/font.css"; // Импортируем CSS с шрифтами
import { createTheme, ThemeProvider } from "@mui/material";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const GlobalStyle = createGlobalStyle`
  a {
    text-decoration: none;
    color: inherit;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'MyriadPro', sans-serif;
  }
`;

const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#00C000',
    },
  },
});

root.render(
  <>
    <GlobalStyle />

    <ThemeProvider theme={outerTheme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </>
);
