import React, { ReactElement, useEffect } from "react";

import { SignupPageStyledWrapper } from "./SignupPage.styled";
import { SignupForm } from "../../components/composit-components/SignupForm/SignupForm";

interface SignupPageProps {}

export const SignupPage = (props: SignupPageProps): ReactElement => {
  const {} = props;

  useEffect(() => {
    document.title = `Sign Up : A&O Plants`;
  }, []);


  return (
    <SignupPageStyledWrapper>
      <h3>Registration</h3>

      <SignupForm />
    </SignupPageStyledWrapper>
  );
};
