import styled, { keyframes } from "styled-components";
import { mobile } from "../../../styles/variables.styled";



export const AcardioneStyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  padding: 60px 0;
  background-color: #fff;

  @media screen and (max-width: ${mobile}) {
    padding: 26px 0;

  }
`;

export const AcardioneItem = styled.div<{ isopen: boolean }>`
  margin-bottom: 10px;
  width: 60%;

  @media screen and (max-width: 768px) {
    width: 90%;
  }

  ${({ isopen }) =>
    isopen &&
    `
    .header {
      background-color: #dbd4c4;
      border: 1px solid #fff;
    }

    .icon {
      transform: rotate(90deg);
    }

    .icon svg {
      transform: rotate(0deg);
    }
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  cursor: pointer;
  background-color: #00C000;
  border-radius: 4px;

  @media screen and (max-width: ${mobile}) {
font-size: 13px;
  }
`;

export const Topic = styled.span`
  flex-grow: 1;
  word-wrap: break-word;
  line-height: 1.3;
  color: #fff;


`;

export const Icon = styled.div<{ isopen: boolean }>`
  transition: transform 0.3s ease;
    margin-right: 18px;
  ${({ isopen }) =>
    isopen &&
    `
    transform: rotate(90deg);
  `}

  svg {
    ${({ isopen }) =>
      isopen
        ? `
      transform: rotate(0deg);
    `
        : `
      transform: rotate(-90deg);
    `}
  }
`;

export const ContentContainer = styled.div<{ isopen: boolean }>`
  overflow: hidden;
  max-height: ${({ isopen }) => (isopen ? "900px" : "0")};
  transition: max-height 0.3s ease;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  line-height: 1.3;
  box-sizing: border-box;
`;

export const ContentInner = styled.div`
  box-sizing: border-box;
  padding: 14px;

  @media screen and (max-width: ${mobile}) {
font-size: 12px;
  }
`;