import React, { ReactElement, useEffect } from "react";

import { ContactsContainer, ContactsInfoContainer, ContactsInfoEmail, ContactsInfoLocation, ContactsInfoNumber, ContactsStyledWrapper, ContactsTitle } from "./Contacts.styled";

import { LiaPhoneSolid } from "react-icons/lia";
import { TfiEmail } from "react-icons/tfi";
import { SlLocationPin } from "react-icons/sl";

interface ContactsPageProps { }

export const ContactsPage = (props: ContactsPageProps): ReactElement => {
  const { } = props;

  useEffect(() => {
    document.title = `Contacts : A&O Plants`;
  }, []);


  return (
    <ContactsStyledWrapper>
      <ContactsContainer>
        <ContactsInfoContainer>
          <ContactsTitle>Contacts</ContactsTitle>
          <ContactsInfoNumber><LiaPhoneSolid size={20} style={{marginRight:"3px"}}/>+44 7832 695407</ContactsInfoNumber>
          <ContactsInfoEmail><TfiEmail size={20} style={{marginRight:"3px"}}/>officeaiop@gmail.com</ContactsInfoEmail>
          <ContactsInfoLocation><SlLocationPin size={20} style={{marginRight:"3px"}}/>Exeter Devon</ContactsInfoLocation>
        </ContactsInfoContainer>
      </ContactsContainer>
    </ContactsStyledWrapper>
  );
};
