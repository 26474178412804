import { RefreshTokenResponseInterface } from "../../types/interfaces/RefreshToken.interface";
import { httpClient } from "../httpClient/HttpClient";

export class AuthApi {
  private AUTH_ENDPOINT = "auth";

  public startSignUp = async (phone: string, isAccepted: boolean): Promise<any> => {
    const data = await httpClient.post(`${this.AUTH_ENDPOINT}/start-signup`, {
      phone,
      isAccepted,
    });

    return data;
  };

  public signUp = async (params: any): Promise<any> => {
    const data = await httpClient.post(`${this.AUTH_ENDPOINT}/signup`, {
      ...params
    });

    return data;
  };
  
  public startSignIn = async (phone: string): Promise<any> => {
    const data = await httpClient.post(`${this.AUTH_ENDPOINT}/start-signin`, {
      phone,
    });

    return data;
  };

  public signIn = async (phone: string, code: string): Promise<any> => {
    const data = await httpClient.post(`${this.AUTH_ENDPOINT}/signin`, {
      phone,
      code,
    });

    return data;
  };

  public refreshToken = async (refreshToken: string): Promise<any> => {
    const data = (await httpClient.post(`${this.AUTH_ENDPOINT}/refresh-token`, {
      refreshToken,
    })) as { data: RefreshTokenResponseInterface };

    return data;
  };
}

export const authApi = new AuthApi();
