import { colors } from "@mui/material";
import styled from "styled-components";

export const InputStyledWrapper = styled.div``;

export const InputStyled = styled.input<{ $isValid: boolean }>`
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    transition: 0.3s;
    min-width: 300px;

    border-color: ${({ $isValid }) => ($isValid ? "#ccc" : colors.red[500])};
    
    &:focus {
        border-color: #000;
    }
`;
